import { SessionAPI } from "../api/SessionAPI";
import store from "../store";
import * as registration from "../store/Registration/actions";
import { localStorageGetItem } from "./storage";
import storeRedux from "../store/store";
export class GetNews {
  public static subscribe() {
    console.log("GetNews Subscribe");
    let sessionClient = localStorageGetItem("sessionId");
    let sessionReg = storeRedux.getState().registration.regSessionId;
    const sessionId = sessionReg || sessionClient;
    if (sessionId) {
      SessionAPI.getNewsLongPollingDoc(sessionId)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            console.log("GetNews Dispatch");
            store.dispatch(registration.newsLongPollingAction(res.data));
            this.subscribe();
          }
        })
        .catch((response) => GetNews.catchLongPolling(response));
    }
  }

  private static catchLongPolling(response: any) {
    if (response.status === 401) {
      store.dispatch(registration.logoutAction());
    }
    if (response.status === 400) {
      console.error(response.message);
    }
  }
}
