import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import store from "./store/store";

const resources = {
  ru: {
    translation: {
      "Город проживания": "Город проживания",
      "Улица проживания": "Улица проживания",
      "Дом проживания": "Дом проживания",
      "Квартира проживания":
        "Квартира проживания",
      "Поиск города": "Поиск города",
      loginBtn: "Войти",
      logout: "Выход",
      writeYourName: "Напишите ваше имя",
      newDocument: "Новый документ",
      dearCustomerPleaseReadTheDocument:
        "Уважаемый клиент, пожалуйста, ознакомьтесь с документом",
      loadFile: "Посмотреть документ",
      important: "Важно:",
      beSureToKeep:
        "Обязательно держите смартфон",
      yourSmartphoneUpright:
        "в вертикальном положении",
      passport_face: "Лицевая сторона паспорта",
      passport_back:
        "Фото оборотной стороны паспорта",
      iClient: store.getState().registration
        .settings.viconInterface
        ? "Я клиент КТ"
        : store.getState().registration.settings
            .name === "ОАО «Кыргызкоммерцбанк»"
        ? "Я клиент видеобанкинга"
        : "Я клиент Банка",
      newClient: "Хочу стать клиентом",
      videoConsult:
        "Видео консультация со специалистами",
      accountOppening: "Открыть счета",
      open: "Открыть",
      cardIssuanceApplication:
        "Заявка на выпуск карты",
      loanApplication:
        "Заявка на получение кредита",
      bankingConnectionApplication:
        "заявка на подключение банкинга",
      videoCallToTheOperator:
        "Видеозвонок оператору",
      login: "Вход в систему",
      further: "Далее",
      enterPhoneNumber: "Введите номер телефона",
      enterPassportNumber:
        "Введите номер паспорта",
      cancel: "Отмена",
      enterYourINN: "Введите свой ИНН",
      enterCodeFromSMS: "Введите код из СМС",
      getCodeAgain: "Получить код еще раз через",
      selectSpecialist: "Выберите специалиста",
      exit: "Выйти",
      startVideoCall: "Начать видеозвонок",
      call: "Звонок",
      payments: "Платежи",
      profile: "Профиль",
      moneyOrders: "Платежные поручения",
      moneyOrder: "Платежное поручение",
      registerAgree:
        "Регистрируясь, вы соглашаетесь на ",
      registerAgreeLink:
        "обработку персональных данных",
      createDocumentTitle:
        "Для создания документа обратитесь к оператору",
      tabNew: "НОВЫЕ",
      tabHistory: "ИСТОРИЯ",
      menuDocs: "Документы",
      menuCountScores: "Расчетные счета",
      menuLang: "Язык - ",
      choiseLang: "Выборите язык",
      confirm: "Подтвердить",
      confirmation: "Подтверждение",
      confirmCorrectness:
        " Вы подтверждаете верность данных?",
      confirmPerson:
        "Для подтверждения вашей личности необходимо связаться с вами по видеосвязи",
      inputOrganizationInn:
        "Введите ИНН Организации",
      organization: "Организация",
      employee: "Сотрудник",
      client: "Клиент",
      signed: "Подписан",
      sign: "Подписать",
      reject: "Отклонить",
      signConfirmation:
        "Вы уверены, что хотите подписать документ?",
      signRejection:
        "Вы уверены, что хотите отклонить документ?",
      confirmCancel:
        "Вы уверены что хотите отменить?",
      approveDocument:
        "Вы уверены, что хотите одобрить документ?",
      rejectDocument:
        "Вы уверены, что хотите отклонить документ?",
      application: "Заявка",
      yes: "Да",
      no: "Нет",
      agreeTerms: "Согласен с условиями",
      signedWithQualifiedDigitalSign:
        "Подписано квалифицированной электронной подписью",
      alert: "Внимание!",
      listen: "Прослушать",
      pleaseListenToNotification:
        "Пожалуйста прослушайте уведомление",
      youNeedToOpenThisWebsiteInBrowser:
        "Вам необходимо открыть этот сайт в браузере {{browser_name}}",
      codeSendedToNumber:
        "Код выслан на номер {{phone_number}}",
      dearCustomer: "Уважаемый клиент",
      systemRequestAccessTo:
        "Система запросит у Вас разрешение на доступ к",
      toCameraAndMicro: "камере и микрофону.",
      pressAllowButton:
        "Обязательно нажмите кнопку “Разрешить”",
      understood: "Понятно",
      fillAllFields: "Заполните все поля",
      dataShouldBeEqualWithPassportData:
        "Данные должны совпадать с паспортом",
      personalNumber: "Персональный номер (ИНН)",
      passportNumber: "Номер паспорта",
      surname: "Фамилия",
      name: "Имя",
      middleName: "Отчество",
      latName: "Имя на латинице",
      latSurname: "Фамилия на латинице",
      nation: "Национальность",
      dateBirth: "Дата рождения",
      datePassport: "Дата выдачи паспорта",
      passportOrg: "Орган, выдавший паспорт",
      passportExpDate:
        "Дата окончания срока действия",
      citizenshipAddress: "Адрес прописки",
      actualCitizenshipAddress:
        "Фактическое место проживания",
      email: "Адрес электронной почты (e-mail)",
      maritalStatus: "Семейное положение",
      placeOfWork: "Место работы",
      position: "Должность",
      codeWord: "Кодовое слово",
      haveBeneficialOwner:
        "У вас есть бенефициарный собственник (выгодоприобретатель)?",
      USCitizenship:
        "Наличие гражданства/резидентства США или Green Card",
      areYouPDL:
        "Являетесь ли вы ПДЛ (публично-должностным лицом)?",
      areYouGovernment:
        "Являетесь ли Вы государственным служащим",
      newTypePassport: "Паспорт нового образца",
      oldTypePassport: "Паспорт старого образца",
      back: "Назад",
      continue: "Продолжить",
      waitOperator: "Ожидайте ответа оператора",
      cancelVideocall: "Отменить видеозвонок",
      regSteps:
        "Шаги регистрации физического лица",
      facePhoto: "Фото лица",
      passportPhoto: "Фото паспорта",
      addFacePhoto: "Добавьте фото лица",
      pickAnotherPhoto: "Выбрать другое фото",
      addPhoto: "Добавить фото",
      regDone: "Регистрация завершена",
      registration: "Регистрация",
      warningAuthText:
        "Необходимая скорость для работы системы 0,5 мегабита в секунду",
      enterYourINNCorrectly:
        "Введите правильно инн",
      enterYourPassportNumber:
        "Введите номер паспорта",
      enterYourPassportNumberCorrectly:
        "Введите правильно номер паспорта",
      enterYourPassportInfo:
        "Введите данные паспорта",
      thePhoneNumberIsNotRegistered:
        "Номер {{phone_number}} не зарегистрирован",
      thereAreNoSignedDocuments:
        "Нет подписанных документов",
      chooseASpecialist: "Выберите специалиста",

      "lang-586a2610-b0ec-44f4-884e-45f2e7fa9eae":
        "Холост/незамужем",
      "lang-800dc893-1ab0-41ea-abcd-46e4050cd914":
        "Женат/замужем",
      "lang-991cbff2-a586-41ab-ac43-f00cc75841a6":
        "В разводе",
      "lang-a04c59a6-ce43-45f6-aae3-54dcfd8b944e":
        "Повторный брак",
      "lang-5a6f089f-b9d4-4232-b001-5b57ddd8e4c8":
        "Вдовец / вдова",
      "lang-8c036cd9-76a8-4688-9acc-2de32bd4228e":
        "Вы неправильно ввели адрес почты",
    },
  },
  kg: {
    translation: {
      "Город проживания":
        "Учурда жашап жаткан шаар",
      "Улица проживания": "Турак жай көчөсү",
      "Дом проживания": "Турак үй",
      "Квартира проживания": "Квартира",
      "Поиск города": "Шаар издөө",
      loginBtn: "Кирүү",
      logout: "Чыгуу",
      writeYourName: "Атыңызды жазыңыз",
      newDocument: "Жаңы документ",
      dearCustomerPleaseReadTheDocument:
        "Урматтуу кардар, документти окуп чыгыңыз",
      loadFile: "Документти көрүү",
      important: "Маанилүү:",
      beSureToKeep: "Смартфонуңузду",
      yourSmartphoneUpright: "тик кармаңыз",
      passport_face: "Паспорттун алдыңкы бети",
      passport_back:
        "Паспорттун арткы бетинин сүрөтү",
      iClient: store.getState().registration
        .settings.viconInterface
        ? "Мен КТ кардарымын"
        : "Мен банктын кардарымын",
      newClient: "Мен кардар болгум келет",
      videoConsult:
        "Адистер менен видео консультация",
      accountOppening: "Эсепти ачуу",
      open: "Документти ачуу",
      cardIssuanceApplication:
        "Карточканы чыгаруу жөнүндө арыз",
      loanApplication: "Насыя алуу үчүн арыз",
      bankingConnectionApplication:
        "Банктык туташуу",
      videoCallToTheOperator:
        "Операторго видео чалуу",
      login: "Кирүү",
      further: "Андан ары",
      enterPhoneNumber:
        "Телефон номерин киргизиңиз",
      cancel: "Жокко чыгаруу",
      enterYourINN: "ИДНни киргизиңиз",
      enterCodeFromSMS: "SMSтен кодду киргизиңиз",
      getCodeAgain: "Кодду дагы бир жолу алыңыз",
      selectSpecialist: "Адис тандоо",
      exit: "Чыгуу",
      startVideoCall: "Видео чалууну баштоо",
      call: "Чалуу",
      payments: "Төлөмдөр",
      profile: "Профиль",
      moneyOrders: "Акча которуулары",
      moneyOrder: "Акча которуу",
      registerAgree: "Каттоо менен, сиз ",
      registerAgreeLink:
        "жеке маалыматтарды иштетүүгө макулдугуңузду билдиресиз",
      createDocumentTitle:
        "Документ түзүү үчүн, операторго кайрылыңыз",
      tabNew: "ЖАҢЫ",
      tabHistory: "Тарых",
      menuDocs: "Келишимдер",
      menuCountScores: "Эсептешүү эсептери",
      menuLang: "Тил - ",
      choiseLang: "Тилди тандаңыз",
      confirm: "Тастыктоо",
      confirmation: "Ырастоо",
      confirmCorrectness:
        "Сиз маалыматтардын тууралыгын ырастайсызбы?",
      confirmPerson:
        "Cиздин өздүгүңүздү тастыктоо үчүн сиз менен видео байланыш аркылуу байланышуу керек",
      inputOrganizationInn:
        "Уюмдун ИНН киргизиңиз",
      organization: "Уюм",
      employee: "Кызматкер",
      client: "Кардар",
      signed: "Кол коюлган",
      sign: "Кол кою",
      reject: "Четке кагуу",
      signConfirmation:
        "Чын эле документке кол койгуңуз келеби?",
      signRejection:
        "Документти чын эле четке кагасызбы?",
      confirmReject:
        "Чын эле жокко чыгаргыңыз келеби?",
      confirmCancel:
        "Чын эле жокко чыгаргыңыз келеби?",
      approveDocument:
        "Документти чын эле жактырасызбы?",
      rejectDocument:
        "Документти чын эле четке кагасызбы?",
      application: "Колдонмо",
      yes: "Ооба",
      no: "Жок",
      agreeTerms: "Мен шарттарга макулмун",
      signedWithQualifiedDigitalSign:
        "Квалификациялуу электрондук колтамга менен кол коюлган",
      alert: "Көңүл буруңуз",
      listen: "ПрослушатьУгуңуз",
      pleaseListenToNotification:
        "Сураныч, билдирүүнү угуңуз",
      youNeedToOpenThisWebsiteInBrowser:
        "Бул сайтты {{browser_name}} браузерде ачышыңыз керек",
      codeSendedToNumber:
        "Смс код {{phone_number}} номерине жиберилди",
      dearCustomer: "Урматтуу кардар",
      systemRequestAccessTo:
        "Тутум сизден кирүүгө уруксат сурайт",
      toCameraAndMicro: "камера жана микрофон.",
      pressAllowButton:
        '"Уруксат берүү" баскычын чыкылдатыңыз',
      understood: "Түшүнүктүү",
      fillAllFields:
        "Бардык талааларды толтуруңуз",
      dataShouldBeEqualWithPassportData:
        "ММаалыматтар паспорт менен дал келүүсү керек",
      personalNumber: "Жеке номери (ИСН)",
      passportNumber: "Паспорт номери",
      surname: "Фамилиясы",
      name: "Аты",
      middleName: "Атасынын аты",
      latName: "Аты латын тамгасы менен",
      latSurname: "Фамилиясы латын тамгасы менен",
      nation: "Улуту",
      dateBirth: "Туулган датасы",
      datePassport: "Паспорт берилген дата",
      passportOrg: "Паспортту берген орган",
      passportExpDate:
        "Аракеттенүү мөөнөтүнүн аяктоо датасы",
      citizenshipAddress: "Жашаган жери",
      actualCitizenshipAddress:
        "Чыныгы жашаган жери",
      email:
        "Электрондук почтасынын дареги (e-mail)",
      maritalStatus: "Үй-бүлөлүк абалы",
      placeOfWork: "Иштеген жери",
      position: "Кызматы",
      codeWord: "Коддук сөз",
      haveBeneficialOwner:
        "Бенефициардык менчик ээсинин бар экендиги",
      USCitizenship:
        "АКШ жарандыгы/резиденттиги же GreenCard бар экендиги",
      areYouPDL:
        "Сиз саясий кызмат адамы болуп эсептелесизби (СКА)?",
      areYouGovernment:
        "Сиз мамлекеттик кызматкерсизби?",
      newTypePassport: "Жаңы паспорт",
      oldTypePassport: "Эски паспорт",
      back: "Артка",
      continue: "Улантуу",
      waitOperator: "Оператордун жообун күтүңүз",
      cancelVideocall:
        "Видео чалууну жокко чыгаруу",
      regSteps: "Жеке каттоо кадамдары",
      facePhoto: "Бет сүрөт",
      passportPhoto: "Паспорттун сүрөтү",
      addFacePhoto: "Бет сүрөтүн кошуңуз",
      pickAnotherPhoto: "Дагы бир сүрөткө тартуу",
      addPhoto: "Сүрөт кошуу",
      regDone: "Каттоо аяктады",
      registration: "Каттоо",
      warningAuthText:
        "Системанын так иштеши үчүн, интернеттин керектүү ылдамдыгы секундасына 0,5 мегабиттен кем болбош керек",
      enterYourINNCorrectly:
        "Туура ИНН номерди киргизиңиз",
      enterYourPassportNumber:
        "Паспортуңуздун номерин киргизиңиз",
      enterYourPassportNumberCorrectly:
        "Туура паспорт номерин киргизиңиз",
      enterYourPassportInfo:
        "Паспортуңуздун маалыматын киргизиңиз",
      thePhoneNumberIsNotRegistered:
        "{{phone_number}} номери катталган эмес",
      thereAreNoSignedDocuments:
        "Кол коюлган документтер жок",
      chooseASpecialist: "адисти тандаңыз",
      "lang-586a2610-b0ec-44f4-884e-45f2e7fa9eae":
        "Бойдок, турмушка чыга элек",
      "lang-800dc893-1ab0-41ea-abcd-46e4050cd914":
        "Үй-бүлөлүү",
      "lang-991cbff2-a586-41ab-ac43-f00cc75841a6":
        "В разводе",
      "lang-a04c59a6-ce43-45f6-aae3-54dcfd8b944e":
        "Кайра үйлөгөн",
      "lang-5a6f089f-b9d4-4232-b001-5b57ddd8e4c8":
        "Жесир",
      "lang-8c036cd9-76a8-4688-9acc-2de32bd4228e":
        "Сиз туура эмес электрондук почта дарегин киргиздиңиз",
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "ru",
    fallbackLng: "ru",
    interpolation: {
      escapeValue: false,
    },
  });
i18n.languages = ["ru", "kg"];
export default i18n;
