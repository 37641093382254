import React, { useState } from "react";
import {
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import "./App.scss";
import AppStack from "../AppStack/AppStack"; // choose your lib
import { Provider } from "react-redux";
import store from "../../store";
import platform from "platform";
import {
  Dialog,
  DialogTitle,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

// @ts-ignore
window.store = store; // dev

const theme = createTheme({
  typography: {
    fontFamily: "Open Sans, sans-serif",
  },
  palette: {
    //@ts-ignore
    mode: "light",
    primary: {
      main: "#007AFF",
      contrastText: "#fff",
    },
  },
});
function App() {
  const { t } = useTranslation();
  const [openBlocker, setOpenBlocker] = useState({
    open: false,
    browserName: "",
  });
  React.useEffect(() => {
    const browserName = platform.name,
      os = platform.os;

    if (
      browserName !== "Chrome Mobile" &&
      os.family === "Android"
    ) {
      setOpenBlocker({
        open: true,
        browserName: "Google Chrome",
      });
    }
    if (
      browserName !== "Safari" &&
      os.family === "iOS"
    ) {
      setOpenBlocker({
        open: true,
        browserName: "Safari",
      });
    }
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Dialog open={openBlocker.open}>
          <DialogTitle
            style={{ textAlign: "center" }}>
            {`${t("alert")}!`}
            <br />
            {t(
              "youNeedToOpenThisWebsiteInBrowser",
              {
                browser_name:
                  openBlocker.browserName,
              }
            )}
          </DialogTitle>
        </Dialog>

        <Switch>
          <Route path="/" component={AppStack} />
        </Switch>
      </ThemeProvider>
    </Provider>
  );
}

export default withRouter(App);
