// @ts-nocheck
import React, {
  useState,
  useEffect,
  Dispatch,
} from "react";
import { connect } from "react-redux";
import { actions, RootState } from "../../store";
import PhoneEnter from "./PhoneEnter";
// import InnEnter from './InnEnter';
import {
  TextField,
  Button,
  CircularProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Box,
  FormControl,
  Input,
  InputAdornment,
  FormHelperText,
} from "@material-ui/core";
import { ModerationAPI } from "../../api/ModerationAPI";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useHistory } from "react-router-dom";
import { AuthIcon } from "../../icons/icons";
import { AddNewClient } from "../../icons/icons";
import { ClientAPI } from "../../api/ClientAPI";
import VideoCall from "../Registration/VideoCall";
import BusyOperators from "../Registration/BusyOperators";
import Solve from "../bricks/Solve";
import VideocamRoundedIcon from "@material-ui/icons/VideocamRounded";
import { useTranslation } from "react-i18next";
import MobileMenu from "../MobileMenu/MobileMenu";
import Documents from "../Client/Documents";
import Profile from "../Profile/Profile";
import {
  IUserInfo,
  SessionAPI,
} from "../../api/SessionAPI";
import { GetNews } from "../../utils/getNews";
import { NewsLongPolling } from "../../store/Registration/types";
import {
  ClientDocumentsAPI,
  IPaymentOrders,
} from "../../api/ClientDocumentsAPI";
import { Action, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import ContractInfo from "../bricks/ContractInfo";
import PaymentOrder from "../Client/PaymentOrder";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { sessionIdAction } from "../../store/Registration/actions";
import PasswordData from "../Registration/PasswordData";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const Authorization: React.FC<Props> = (
  props
) => {
  const {
    settings,
    newsLongPolling,
    newsLongPollingAction,
    regSessionId,
    tabValue,
    solve,
    setSessionId,
    setIsVideoCallActive,
  } = props;
  let history = useHistory();
  const [start, setStart] = useState(false);
  const [title, setTitle] = useState("");
  const [guest, setGuest] = useState(false);
  const [userName, setUserName] = useState("");
  const [
    conferenceJoinLink,
    setConferenceJoinLink,
  ] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] =
    useState({
      error: false,
      message: "",
    });
  const [
    currentPaymentOrder,
    setCurrentPaymentOrder,
  ] = useState<IPaymentOrders | null>(null);
  const [pulingOpen, setPulingOpen] =
    useState(false);
  const [doc, setDoc] = useState({});
  const [document, setDocument] = useState<any>(
    {}
  );
  const [currentContract, setCurrentContract] =
    useState<any | null>(null);
  const [loadDoc, setLoadDoc] = useState(true);
  const [innViewState, setInnView] =
    useState(false);
  const [innValue, setInnValue] = useState("");
  const [innErorr, setInnError] = useState(false);
  const [childDocuments, setChildDocuments] =
    useState<any>([]);
  const [innMessage, setInnMessage] = useState(
    "Введите правильно инн"
  );
  console.warn(setInnMessage);
  //@ts-ignore
  const [documentHTML, setDocumentHTML] =
    useState<HTMLIFrameElement>("");

  const [requestId, setRequestId] = useState<
    number | null
  >(null);
  const [status, setStaus] = useState("");
  const [action, setAction] = useState("");
  const { t, i18n } = useTranslation();
  const activeLanguage =
    localStorage.getItem("i18nextLng");
  const catchError = (message: string) => {
    setErrorMessage({
      error: true,
      message: message,
    });
    setLoading(false);
  };
  const [userInfo, setUserInfo] =
    useState<IUserInfo>({
      inn: "",
      fullName: "",
      userId: 0,
    });

  useEffect(() => {
    if (regSessionId) {
      action === "registration_Complete" &&
        SessionAPI.getUserInfo(regSessionId).then(
          (data) => {
            setUserInfo({
              inn: data.inn,
              fullName: data.fullName,
              userId: data.userId,
            });
          }
        );
    }
  }, [regSessionId, action]);
  const startVideoCallForGuest = () => {
    setLoading(true);
    setIsVideoCallActive(true);

    ModerationAPI.startVideoCallForGuest(userName)
      .then((data) => {
        setRequestId(data.requestId);
        setErrorMessage({
          error: false,
          message: "",
        });
        setConferenceJoinLink(
          data.conferenceJoinLink
        );
      })
      .catch(({ response }) =>
        catchError(response.data.message)
      );
  };

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    if (requestId) {
      if (window.location.pathname === "/") {
        const getVideoModerationStatusInterval =
          setInterval(() => {
            ClientAPI.getVideoCallStatus(
              "{0EBA066D-CF2A-44B7-9384-DDF677DFA210}",
              requestId
            )
              .then((data) => {
                setStaus(data.status);
                setLoading(false);

                if (
                  data.status ===
                  "userIsRegistered"
                ) {
                  setSessionId(
                    localStorage.getItem(
                      "regSessionId"
                    )
                  );
                }

                if (
                  data.status ===
                    "responseTimeout" &&
                  data.action !==
                    "registration_WaitModeration"
                ) {
                  setRequestId(null);
                  setIsVideoCallActive(false);
                  return () =>
                    clearInterval(
                      getVideoModerationStatusInterval
                    );
                }
                if (
                  data.status ===
                    "conversationIsOver" ||
                  (data.status ===
                    "responseTimeout" &&
                    data.action ===
                      "registration_WaitModeration")
                ) {
                  // window.location.reload(false);
                  setRequestId(null);
                  setIsVideoCallActive(false);
                  return () =>
                    clearInterval(
                      getVideoModerationStatusInterval
                    );
                }
                if (data.action) {
                  setAction(data.action);
                }
              })
              .catch(({ response }) => {
                console.log(response);
                console.error(
                  response.data.message
                );
              });
          }, 1500);
        return () =>
          clearInterval(
            getVideoModerationStatusInterval
          );
      }
    }
    // getVideoCallStatus();
  }, [
    requestId,
    setStaus,
    setLoading,
    setAction,
    setIsVideoCallActive,
    setSessionId,
  ]);

  useEffect(() => {
    if (status === "userIsRegistered") {
      GetNews.subscribe();
    }
  }, [status, newsLongPollingAction]);

  useEffect(() => {
    //@ts-ignore
    if (
      newsLongPolling &&
      newsLongPolling.length
    ) {
      //@ts-ignore
      newsLongPolling.forEach(
        (item: NewsLongPolling) => {
          if (item.eventName === "NewDocument") {
            setDoc(item);
          }
        }
      );
    }
  }, [newsLongPolling, doc]);

  useEffect(() => {
    //@ts-ignore
    if (newsLongPolling && doc.eventParameters) {
      setPulingOpen(true);
    }
    //@ts-ignore
    setDocument(doc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doc]);

  const pulingOpenClick = () => {
    if (regSessionId && doc) {
      //@ts-ignore
      if (
        doc.eventParameters.documentType ===
        "DocumentByTemplate"
      ) {
        //@ts-ignore
        setChildDocuments(
          doc.eventParameters.childDocuments
        );
        //@ts-ignore
        ClientDocumentsAPI.getDocument(
          regSessionId,
          doc.eventParameters.documentId
        )
          .then((res) => {
            if (res.documentStatus === "New") {
              //@ts-ignore
              setLoadDoc(false);
              setPulingOpen(false);
              setCurrentContract(res);
              setDocumentHTML(res.documentHtml);
            }
          })
          .catch(({ response }) => {
            console.error(response);
          });
        //@ts-ignore
      } else if (
        doc.eventParameters.documentType ===
        "PaymentOrder"
      ) {
        //@ts-ignore
        ClientDocumentsAPI.getDocument(
          sessionId,
          doc.eventParameters.documentId
        )
          .then((data) => {
            setLoadDoc(false);
            setPulingOpen(false);
            setCurrentPaymentOrder(data);
          })
          .catch(({ response }) => {
            console.error(response);
          });
      }
    }
  };

  const authClick = (title: string) => {
    setStart(true);
    setTitle(title);
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } =
      props;
    return (
      <div
        role="tabpanel"
        hidden={tabValue !== index}
        {...other}
      >
        {tabValue === index && (
          <div>{children}</div>
        )}
      </div>
    );
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  const authSelectView = () => {
    return (
      <div
        className="authSelect__content"
        style={
          settings
            ? {
                backgroundColor:
                  settings.colorBox,
              }
            : {}
        }
      >
        <div className="authorization__logo">
          {settings && (
            <img
              src={settings.logo}
              alt={settings.name}
            />
          )}
        </div>
        <div
          className="authSelect__item"
          onClick={() => authClick(t("login"))}
          style={
            settings
              ? {
                  backgroundColor: settings.color,
                }
              : {}
          }
        >
          <span className="authSelect__icon">
            <AuthIcon />
          </span>
          <span
            className="authSelect__text"
            style={
              settings
                ? { color: settings.colorText }
                : {}
            }
          >
            {t("iClient")}
          </span>
        </div>
        {settings.name ===
          "ОАО «Кыргызкоммерцбанк»" ||
        settings.name === "Бакай Банк" ? (
          ""
        ) : (
          <div
            className="authSelect__item authSelect__border"
            onClick={() => authClick(t("login"))}
            style={
              settings
                ? {
                    borderColor: settings.color,
                    color: settings.color,
                  }
                : {}
            }
          >
            <span className="authSelect__icon">
              <AddNewClient
                color={settings.color}
              />
            </span>
            <span
              className="authSelect__text"
              style={
                settings
                  ? { color: settings.colorText }
                  : {}
              }
            >
              {t("newClient")}
            </span>
          </div>
        )}

        <div
          className="authSelect__item authSelect__border"
          onClick={() => setGuest(true)}
          style={
            settings
              ? {
                  borderColor: settings.color,
                  color: settings.color,
                }
              : {}
          }
        >
          <span className="authSelect__icon">
            <VideocamRoundedIcon />
          </span>
          <span
            className="authSelect__text"
            style={
              settings
                ? { color: settings.colorText }
                : {}
            }
          >
            {t("newClient")}
          </span>
        </div>
      </div>
    );
  };

  const userNameView = () => {
    return (
      <div className="authSelect__content">
        <div className="authorization__logo">
          {settings && (
            <img
              src={settings.logo}
              alt={settings.name}
            />
          )}
        </div>
        <TextField
          error={errorMessage.error}
          fullWidth
          label={t("enterPhoneNumber")}
          value={userName}
          onChange={(event) =>
            setUserName(event.target.value)
          }
          helperText={errorMessage.message}
        />
        <div className="btnRow">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disableElevation
            disabled={loading}
            startIcon={
              loading && (
                <CircularProgress size={26} />
              )
            }
            onClick={startVideoCallForGuest}
          >
            {t("loginBtn")}
          </Button>
        </div>
      </div>
    );
  };

  const exitClick = () => {
    setIsVideoCallActive(false);
    history.push("/");
    window.location.reload(false);
  };
  // if (true) {
  //   return <PasswordData />;
  // }
  if (
    status === "waitingOperator" &&
    action !== "registration_WaitModeration"
  ) {
    return <VideoCall />;
  }

  if (
    status === "responseTimeout" &&
    action !== "registration_WaitModeration"
  ) {
    return <BusyOperators />;
  }
  const openIframe = () => {
    if (!solve) {
      return <Solve />;
    } else {
      return (
        <iframe
          style={
            tabValue === 0 &&
            status === "userIsRegistered"
              ? { zIndex: 9, opacity: 1 }
              : status !== "userIsRegistered" &&
                tabValue === 0
              ? {}
              : {
                  zIndex: -1,
                  opacity: 0,
                }
          }
          src={conferenceJoinLink}
          className="iframe__content"
          allow="camera;microphone"
          title="video call"
        />
      );
    }
  };

  const guestRegistration = () => {
    if (
      action !== "" &&
      requestId &&
      action !== "registration_WaitModeration"
    ) {
      return (
        <div
          className={`authorization`}
          // ${isFocused ? 'isFocused' : 'isBlur'} up and down move component REMOVE IT or CHECK IT
          //  style={{position: solve ? "fixed" : "static"}}
          style={{ position: "absolute" }}
        >
          <PhoneEnter
            settings={settings}
            guestReg={true}
            requestId={requestId}
            title={t("registration")}
          />
        </div>
      );
    }
  };

  if (
    (status === "activeConversation" &&
      conferenceJoinLink !== "") ||
    action === "registration_Complete" ||
    action === "registration_WaitModeration"
  ) {
    return (
      <>
        <div className="iframe">
          {openIframe()}
          {action === "registration_Complete" ? (
            <>
              <TabPanel
                value={tabValue}
                index={1}
              >
                <Documents userInfo={userInfo} />
              </TabPanel>
              <TabPanel
                value={tabValue}
                index={2}
              >
                <Profile
                  bottom={true}
                  userInfo={userInfo}
                />
              </TabPanel>
              <MobileMenu />
            </>
          ) : (
            <div className="exitGuest">
              <div className="exitGuest__logo">
                {settings && (
                  <img
                    src={
                      settings.logoClient ||
                      settings.logo
                    }
                    alt={settings.name}
                  />
                )}
              </div>
              <ListItem
                button
                className="exitGuest__btn"
                onClick={exitClick}
              >
                <ListItemText
                  primary={t("logout")}
                />
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
              </ListItem>
            </div>
          )}
        </div>
        {action !== "registration_Complete" &&
          guestRegistration()}
        {/* <Dialog open={pulingOpen} className="modal confirm">
          <DialogTitle>{t("newDocument")}</DialogTitle>
          <DialogContent>
            <p className="modal__text">
              {t("dearCustomerPleaseReadTheDocument")}
            </p>
          </DialogContent>
          <DialogActions className="modal__actions">
            <Button onClick={() => setPulingOpen(false)} color="primary">
              {t("cancel")}
            </Button>
            <Button
              onClick={pulingOpenClick}
              variant="contained"
              color="primary"
              disableElevation>
              {t("open")}
            </Button>
          </DialogActions>
        </Dialog> */}
        {Boolean(currentContract?.departmentId) &&
          !loadDoc && (
            <ContractInfo
              childDocuments={childDocuments}
              id={
                document &&
                document.eventParameters
                  .documentId
              }
              departmentId={
                currentContract.departmentId
              }
              html={documentHTML}
              contract={currentContract}
              userInfo={userInfo}
              document={document}
              setCurrentContract={
                setCurrentContract
              }
            />
          )}
        {currentPaymentOrder && !loadDoc && (
          <PaymentOrder
            paymentOrder={currentPaymentOrder}
            setPaymentDetail={
              setCurrentPaymentOrder
            }
            userInfo={userInfo}
          />
        )}
      </>
    );
  }

  const innViewFunc = () => {
    //@ts-ignore
    if (
      !innValue[0].includes("1") &&
      !innValue[0].includes("2")
    ) {
      return setInnError(true);
    }
    setGuest(true);
    setInnView(false);
  };
  const innView = () => {
    return (
      <div className="authorization__form">
        <FormControl
          fullWidth
          className="authorization__pinInput"
          error={innErorr}
        >
          <Input
            inputProps={{
              maxLength: 14,
              autoComplete: "off",
            }}
            value={innValue}
            onChange={(e) => {
              let value = e.target.value
                .toString()
                .replaceAll(" ", "");
              if (value.length <= 14) {
                if (
                  value.startsWith("1") ||
                  value.startsWith("2")
                ) {
                  setInnValue(value);
                } else {
                  setInnValue(
                    value.slice(
                      0,
                      value.length - 1
                    )
                  );
                }
              }
            }}
            endAdornment={
              <InputAdornment
                position="end"
                className="authorization__clear"
              >
                <span
                  style={{ color: "#c3c3c3" }}
                >
                  {innValue.length + ` / 14`}
                </span>
              </InputAdornment>
            }
            type="number"
            placeholder={t("enterYourINN")}
          />
          {innErorr && (
            <FormHelperText>
              {innMessage}
            </FormHelperText>
          )}
        </FormControl>
        <div className="btnRow">
          <Button
            onClick={innViewFunc}
            disabled={innValue.length !== 14}
            fullWidth
            variant="contained"
            color="primary"
            disableElevation
          >
            {t("further")}
          </Button>
        </div>
      </div>
    );
  };
  const confirmCloseClient = () => {
    if (window.confirm(t("confirmCancel"))) {
      window.location.reload();
    }
  };
  return (
    <>
      <div className={`authorization`}>
        {start ? (
          <PhoneEnter
            closePhoneEnter={() => {
              setStart(false);
              setTitle("");
            }}
            settings={settings}
            guestReg={false}
            title={title}
          />
        ) : (
          <div
            className="content authSelect"
            style={
              settings
                ? {
                    backgroundImage: `linear-gradient(${settings.gradientColor}`,
                  }
                : {}
            }
          >
            <Box>
              {guest
                ? userNameView()
                : authSelectView()}
              {!guest && (
                <div className="langGroup">
                  <Button
                    color="primary"
                    className={
                      activeLanguage === "ru"
                        ? "active"
                        : ""
                    }
                    onClick={() =>
                      changeLanguage("ru")
                    }
                  >
                    Русский
                  </Button>
                  <Button
                    color="primary"
                    className={
                      activeLanguage === "kg"
                        ? "active"
                        : ""
                    }
                    onClick={() =>
                      changeLanguage("kg")
                    }
                  >
                    Кыргызча
                  </Button>
                </div>
              )}
              <p
                style={{
                  color: "white",
                  margin: 10,
                  fontSize: 13,
                  textAlign: "center",
                }}
              >
                {t("warningAuthText")}
              </p>
              <p
                style={{
                  color: "white",
                  margin: 10,
                  textAlign: "center",
                }}
              >
                {t("registerAgree")}
                <a
                  href={
                    settings.privacy_policy ?? "#"
                  }
                  style={{ color: "white" }}
                >
                  {t("registerAgreeLink")}
                </a>
              </p>
            </Box>
          </div>
        )}
      </div>
      <Dialog
        className="innView"
        open={innViewState}
      >
        <div className="title">{t("login")}</div>
        <div
          onClick={confirmCloseClient}
          className="back"
        >
          <ArrowBackIosRoundedIcon />
          {t("cancel")}
        </div>
        {innView()}
        <DialogContent>
          <div className="authorization__logo">
            {settings && (
              <img
                src={settings.logo}
                alt={settings.name}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  settings: state.registration.settings,
  solve: state.registration.solve,
  tabValue: state.registration.tabValue,
  regSessionId: state.registration.regSessionId,
  newsLongPolling:
    state.registration.newsLongPolling,
});
const mapDispatchToProps = (
  dispatch: Dispatch<Action> &
    ThunkDispatch<any, any, AnyAction>
) => ({
  newsLongPollingAction: (
    newsLongPolling: NewsLongPolling
  ) =>
    dispatch(
      actions.registration.newsLongPollingAction(
        newsLongPolling
      )
    ),
  setSessionId: (sessionId) =>
    dispatch(sessionIdAction(sessionId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Authorization);
