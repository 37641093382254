import axios, { AxiosInstance } from 'axios';
import { baseUrl, baseUrlRegistration } from '../utils/baseUrl';
// import store from "../store";
// import {logoutAction} from "../store/Registration/actions";

export class InstanceHead {
    public static instance: AxiosInstance
    public static init(apiKey: string) {
        InstanceHead.instance = axios.create({
            baseURL: `${baseUrl()}api/`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "apiKey" : apiKey
            }
        })
    }
}


export class InstanceHeadRegistration {
    public static instance: AxiosInstance
    public static init(apiKey: string) {
        InstanceHeadRegistration.instance = axios.create({
            baseURL: `${baseUrlRegistration()}api/`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "apiKey" : apiKey,
                "deviceId": "Desktop"
            }
        })
    }
}
export class InstanceDocumentHead {
    public static instance: AxiosInstance;
    public static init(url: string) {
        InstanceDocumentHead.instance = axios.create({
            baseURL: url,
            headers: {
                "Access-Control-Allow-Origin": "*",
            }
        })
    }
}

// InstanceHead.instance.interceptors.response.use(
//     response => response,
//     error => {
//         const {status} = error.response;
//         if (status === 401) {
//             store.dispatch(logoutAction());
//         }
//         return Promise.reject(error);
//     }
// );
// InstanceDocumentHead.instance.interceptors.response.use(
//     response => response,
//     error => {
//         const {status} = error.response;
//         if (status === 401) {
//             store.dispatch(logoutAction());
//         }
//         return Promise.reject(error);
//     }
// );
