import React, { Dispatch, useState } from 'react';
import { connect } from 'react-redux';
import { actions } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { Button } from '@material-ui/core';
import ConfirmModal from '../bricks/ConfirmModal';
import { IPaymentOrders } from '../../api/ClientDocumentsAPI';
import Moment from 'react-moment';
import 'moment/locale/ru';
import { IUserInfo } from '../../api/SessionAPI';
import { SignedIcon } from '../../icons/icons';
import Documents from './Documents';
import { useTranslation } from 'react-i18next'

type Props = ReturnType<typeof mapDispatchToProps> & {
    paymentOrder: IPaymentOrders;
    setPaymentDetail?: React.Dispatch<React.SetStateAction<IPaymentOrders | null>>
    userInfo: IUserInfo
}

const PaymentOrder:React.FC<Props> = (props) => {
    const {
        paymentOrder,
        setPaymentDetail,
        userInfo,
        tabValueAction,
        paymentTabValueAction
    } = props;
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openConfirmReject, setOpenConfirmReject] = useState(false);
    const [documentStatus, setDocumentStatus] = useState(paymentOrder.documentStatus);
    const [goBack, setGoBack] = useState(false);
    const {t} = useTranslation()

    const confirmClickOpen = () => {
        setOpenConfirm(true);
    }

    const confirmClickClose = () => {
        setOpenConfirm(false);
    }

    const confirmClickOpenReject = () => {
        setOpenConfirmReject(true);
    }

    const confirmClickCloseReject = () => {
        setOpenConfirmReject(false);
    }

    if(goBack) {
        if(setPaymentDetail) {
            setPaymentDetail(null);
            tabValueAction(1);
            paymentTabValueAction(1);
        }
        return <Documents userInfo={userInfo} />
    }
    let data = JSON.parse(paymentOrder.documentJson);
    return (
        <div className="paymentOrder">
            {documentStatus !== "New" &&<div className="cancel" onClick={() => setGoBack(true)}><ArrowBackIosRoundedIcon /></div>}
            <div className="title">{t('moneyOrder')} <br /> {documentStatus === "Signed" && <span>от <Moment format="DD MM YYYY">{paymentOrder.createdDate}</Moment></span>}</div>
            <div className="payment">
                <div className="payment__title">{data.paymentRecipient.name}</div>
                <p>Сумма: {data.paymentRecipient.amount} {data.paymentRecipient.currency}</p>
                {/* <p><Moment format="D MMMM YYYY">{paymentOrder.document.date}</Moment></p> */}
            </div>
            <div className="payment">
                <div className="payment__title">Получатель</div>
                <p>{data.paymentRecipient.target}</p>
                <p>{data.paymentRecipient.bank}</p>
                <p>БИК: {data.paymentRecipient.bik}</p>
                <p>Р/с: {data.paymentRecipient.paymentAccount}</p>
                <p>{data.paymentRecipient.paymentCode}</p>
            </div>
            <div className="payment borderNone">
                <div className="payment__title">Плательщик</div>
                <p>{userInfo.fullName}</p>
                <p>Р/с: {data.payer.accountOfBank}</p>
            </div>
            {documentStatus === "Signed" &&
                <div className="signed">
                    <div className="signed__fullName">{userInfo.fullName}</div>
                    <div className="signed__signature">
                        Подписан: <span><Moment format="DD.MM.YYYY">{paymentOrder.createdDate}</Moment></span> в <span><Moment format="hh:mm:ss">{paymentOrder.createdDate}</Moment></span>
                    </div>
                    <div className="signed__item">
                        <div className="signed__icon"><SignedIcon /></div>
                        Подписано квалифициронной электронной подписью
                    </div>
                </div>
            }
            {documentStatus === "New" &&
                <div className="btnRow">
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={confirmClickOpen}>Подписать</Button>
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        disableElevation
                        onClick={confirmClickOpenReject}>Отклонить</Button>
                </div>
            }
            <ConfirmModal
                openConfirm={openConfirm}
                confirmClickClose={confirmClickClose}
                title={t('confirmation')}
                text={t('approveDocument')}
                buttonText={t('confirm')}
                cinfirm={true}
                documentId={paymentOrder.documentId}
                setDocumentStatus={setDocumentStatus}
                setGoBack={setGoBack}/>
            <ConfirmModal
                openConfirm={openConfirmReject}
                confirmClickClose={confirmClickCloseReject}
                title="Заявка"
                text={t('rejectDocument')}
                buttonText="Да"
                cinfirm={false}
                documentId={paymentOrder.documentId}
                setDocumentStatus={setDocumentStatus}
                setGoBack={setGoBack} />
        </div>
    );
}

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    tabValueAction: (tabValue: number) => dispatch(actions.registration.tabValueAction(tabValue)),
    paymentTabValueAction: (paymentTabValue: number) => dispatch(actions.registration.paymentTabValueAction(paymentTabValue)),
});

export default connect(null, mapDispatchToProps)(PaymentOrder);
