import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store";
import { Button, FormControlLabel, Checkbox } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { DocumentsAPI } from "../../api/DocumentsAPI";
import { useTranslation } from "react-i18next";
import CalncelDilalog from "../bricks/CalncelDilalog";
import { RegistrationAPI } from "../../api/RegistrationAPI";

type Props = ReturnType<typeof mapStateToProps> & {
  guestRegistrationEnd: boolean;
  setGuestRegistrationEnd: (state: boolean) => void;
};

interface Documents {
  documentType: string;
  linkText: string;
  text: string;
}

const RegistrationEnd: React.FC<Props> = ({
  regSessionId,
  guestRegistrationEnd,
  setGuestRegistrationEnd,
  apiKey,
}) => {
  let history = useHistory();
  const [title, setTitle] = useState("");
  const [documents, setDocuments] = useState<Documents[]>([]);
  const [openCancel, setOpenCancel] = useState(false);
  const [guestIdentification, setGuestIdentification] = useState(false);

  const { t } = useTranslation();

  const cencelClickOpen = () => {
    setOpenCancel(true);
  };

  const cencelClickClose = () => {
    setOpenCancel(false);
  };

  const checkedChange = (event: React.ChangeEvent<HTMLInputElement>) => {};

  const linkTextClick = (documentType: string) => {
    if (regSessionId) {
      window.open(
        `https://smartidregistration.onoi.kg/api/Documents/GetDocument/${regSessionId}/${documentType}/${apiKey}`,
        "_blank"
      );
      // DocumentsAPI.getDocument(regSessionId, documentType).then(data => {
      //     console.log(data)
      // })
    }
  };

  const confirmUserRegistrationClick = () => {
    if (guestRegistrationEnd) {
      if (regSessionId) {
        RegistrationAPI.startVideoModeration(regSessionId)
          .then((data) => {
            if (data.status === "fail") {
              alert(data.message);
            }
            if (data.status === "success") {
              setGuestIdentification(true);
            }
          })
          .catch(({ response }) => alert(response.data.message));
      }
    } else {
      return history.push("/identification");
    }
  };

  useEffect(() => {
    if (regSessionId) {
      DocumentsAPI.getDocumentsForCompletingRegistration(regSessionId).then(
        (data) => {
          setTitle(data.result.title);
          setDocuments(data.result.documents);
        }
      );
    }
  }, [regSessionId]);

  return (
    <div
      className="content registrationEnd"
      style={guestIdentification ? { display: "none" } : {}}>
      <Button className="cancel" color="primary" onClick={cencelClickOpen}>
        {t("cancel")}
      </Button>
      <div className="title">{t("regDone")}</div>
      <div className="secretWord__desc">{title}</div>
      <ul className="registrationEnd__list">
        {documents.map((document) => (
          <li key={document.documentType}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={true}
                  name={document.documentType}
                  color="primary"
                  onChange={checkedChange}
                />
              }
              label={
                <span>
                  {document.text}{" "}
                  <span
                    className="link"
                    onClick={() => linkTextClick(document.documentType)}>
                    {document.linkText}
                  </span>
                </span>
              }
            />
          </li>
        ))}
      </ul>
      <div className="btnRow">
        <Button
          fullWidth
          variant="contained"
          color="primary"
          disableElevation
          onClick={confirmUserRegistrationClick}>
          {t("confirm")}
        </Button>
      </div>
      <CalncelDilalog
        openCancel={openCancel}
        cencelClickClose={cencelClickClose}
        onConfirm={() => {
          setGuestRegistrationEnd(false);
        }}
        backHistory="/"
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  regSessionId: state.registration.regSessionId,
  apiKey: state.registration.settings.apiKey,
});

export default connect(mapStateToProps)(RegistrationEnd);
