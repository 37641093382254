import React, { Dispatch, useState } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { Action, AnyAction } from "redux";
import {
  Button,
  FormControl,
  Input,
  InputAdornment,
  IconButton,
  FormHelperText,
  Dialog,
  TextField,
  CircularProgress,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import {
  NavLink,
  useHistory,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ClearIcon } from "../../icons/icons";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { RegistrationAPI } from "../../api/RegistrationAPI";
import { AuthAPI } from "../../api/AuthAPI";
import InputMask from "react-input-mask";
import Axios from "axios";
import { baseUrlRegistration } from "../../utils/baseUrl";
import PhotoFace from "../Registration/PhotoFace";
import { makeStyles } from "@material-ui/styles";

type Props = ReturnType<
  typeof mapDispatchToProps
> &
  ReturnType<typeof mapStateToProps> & {
    closePhoneEnter: () => void;
    guestReg: boolean;
    requestId?: number;
    title: string;
  };

const PhoneEnter: React.FC<Props> = (props) => {
  let history = useHistory();
  const initialError = {
    error: false,
    message: "",
  };
  const {
    startRegistrationAction,
    sessionIdAction,
    solve,
    isAuthorizedAction,
    settings,
    guestReg,
    requestId,
    title,
    closePhoneEnter,
  } = props;
  const classes = useStyles();
  const [confirm, setConfirm] = useState(false);
  const [phone, setPhone] = useState("996");
  const [pinCode, setPinCode] = useState("");
  const [errorPhone, setErrorPhone] =
    useState(initialError);
  const [errorPinCode, setErrorPinCode] =
    useState(initialError);
  const [regConfirm, setRegConfirm] =
    useState(false);
  const [userFound, setUserFound] =
    useState(false);
  const [sessionIdReg, setSessionIdReg] =
    useState<string | null>(null);
  const newPhone = phone.replace(/[^0-9]/gim, "");
  const [guestRegOpen, setRegGuestOpen] =
    useState(false);
  const [codeSuccess, setCodeSuccess] =
    useState(false);
  const [innViewState, setInnView] =
    useState(false);
  const [innValue, setInnValue] = useState("");
  const [
    passportNumberValue,
    setPassportNumberValue,
  ] = useState("");
  const [grsViewState, setGRSView] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [grsError, setGRSError] = useState({
    innError: false,
    passportError: false,
    innMessage: "",
    passportMessage: "",
  });
  const [innErorr, setInnError] = useState(false);
  const [isBtnDisabled, setBtnDisabled] =
    useState(true);
  const [timeLeft, setTimeLeft] = useState(60);
  const { t } = useTranslation();

  React.useEffect(() => {
    const timer = setInterval(
      () => setTimeLeft(timeLeft - 1),
      1000
    );

    if (timeLeft > 0) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
      clearTimeout(timer);
    }

    return () => clearInterval(timer);
  }, [timeLeft, setTimeLeft]);

  const phoneClick = () => {
    setTimeLeft(60);
    setBtnDisabled(true);

    if (guestReg) {
      registrationClick();
    } else {
      AuthAPI.authByPhone(newPhone)
        .then((data) => {
          if (data.result === "userFound") {
            setErrorPhone({
              error: false,
              message: "",
            });
            setConfirm(true);
            setUserFound(true);
          }
          if (data.result === "userNotFound") {
            setRegConfirm(true);
            setTimeout(() => {
              closePhoneEnter();
            }, 2000);
          }
        })
        .catch(({ response }) =>
          setErrorPhone({
            error: true,
            message: response.data.message,
          })
        );
    }
  };

  const registrationClick = () => {
    if (guestReg) {
      if (settings && requestId) {
        const headers = {
          "Access-Control-Allow-Origin": "*",
          apiKey: settings.apiKey,
          deviceId: "Desktop",
          requestId: requestId,
        };
        Axios.post(
          `${baseUrlRegistration()}api/Registration/StartRegistration/${newPhone}`,
          {},
          {
            headers: headers,
          }
        )
          .then((res) => {
            if (res.data.status === "fail") {
              setErrorPhone({
                error: true,
                message: res.data.message,
              });
            }
            if (res.data.status === "success") {
              if (
                res.data.result
                  .phoneAlreadyRegistered === true
              ) {
                setErrorPhone({
                  error: true,
                  message:
                    res.data.result.message,
                });
                return;
              }
              setSessionIdReg(
                res.data.result.sessionId
              );
              setErrorPhone({
                error: false,
                message: "",
              });
              setConfirm(true);
            }
          })
          .catch(({ response }) =>
            setErrorPhone({
              error: true,
              message: response.data.message,
            })
          );
      }
    } else {
      RegistrationAPI.startRegistration(newPhone)
        .then((data) => {
          if (data.status === "fail") {
            setErrorPhone({
              error: true,
              message: data.message,
            });
          }
          if (data.status === "success") {
            if (
              data.result
                .phoneAlreadyRegistered === true
            ) {
              setErrorPhone({
                error: true,
                message: data.result.message,
              });
              return;
            }
            setSessionIdReg(
              data.result.sessionId
            );
            setErrorPhone({
              error: false,
              message: "",
            });
            setConfirm(true);
          }
        })
        .catch(({ response }) =>
          setErrorPhone({
            error: true,
            message: response.data.message,
          })
        );
    }
    setRegConfirm(false);
  };

  const pinCodeClick = () => {
    if (userFound) {
      AuthAPI.phonePinConfirm(pinCode, newPhone)
        .then((data) => {
          if (data.approve) {
            sessionIdAction(data.sessionId);
            isAuthorizedAction(true);
            setErrorPinCode({
              error: false,
              message: "",
            });
            return history.push("/client");
          } else {
            setErrorPinCode({
              error: true,
              message:
                "Введите правильный pinCode",
            });
          }
        })
        .catch(({ response }) =>
          setErrorPhone({
            error: true,
            message: response.data.message,
          })
        );
    } else {
      if (sessionIdReg) {
        RegistrationAPI.checkSmsCode(
          sessionIdReg,
          pinCode
        )
          .then((data) => {
            if (data.status === "fail") {
              setErrorPinCode({
                error: true,
                message: data.message,
              });
            }
            if (data.status === "success") {
              startRegistrationAction(
                sessionIdReg
              );
              setErrorPinCode({
                error: false,
                message: "",
              });
              setCodeSuccess(true);
              if (guestReg) {
                return settings.name ===
                  "Бакай Банк" ||
                  settings.name ===
                    "ОАО «Кыргызкоммерцбанк»"
                  ? setGRSView(true)
                  : setRegGuestOpen(true);
              } else {
                isAuthorizedAction(true);
                return history.push(
                  "/stepIndividual"
                );
              }
            }
          })
          .catch(({ response }) =>
            setErrorPinCode({
              error: true,
              message: response.data.message,
            })
          );
      }
    }
  };

  const phoneView = () => {
    return (
      <div className="authorization__form">
        <InputMask
          mask={"+999 999 99-99-99"}
          value={phone}
          onChange={(event) =>
            setPhone(event.target.value)
          }
        >
          {(inputProps: any) => (
            <TextField
              {...inputProps}
              label={t("enterPhoneNumber")}
              fullWidth
              error={errorPhone.error}
              helperText={errorPhone.message}
            />
          )}
        </InputMask>
        <div className="btnRow">
          <Button
            onClick={phoneClick}
            fullWidth
            variant="contained"
            color="primary"
            disableElevation
          >
            {t("further")}
          </Button>
        </div>
      </div>
    );
  };

  const innViewFunc = () => {
    //@ts-ignore
    if (
      !innValue[0].includes("1") &&
      !innValue[0].includes("2")
    ) {
      return setInnError(true);
    }
    setInnView(true);
  };

  const innView = () => {
    return (
      <div className="authorization__form">
        <FormControl
          fullWidth
          className="authorization__pinInput"
          error={innErorr}
        >
          <Input
            inputProps={{
              maxLength: 14,
              autoComplete: "off",
            }}
            value={innValue}
            onChange={(e) => {
              let value = e.target.value.trim();
              if (value.length <= 14) {
                if (
                  value.startsWith("1") ||
                  value.startsWith("2")
                ) {
                  setInnValue(value);
                } else {
                  setInnValue(
                    value.slice(
                      0,
                      value.length - 1
                    )
                  );
                }
              }
            }}
            endAdornment={
              <InputAdornment
                position="end"
                className="authorization__clear"
              >
                <span
                  style={{ color: "#c3c3c3" }}
                >
                  {innValue.length + ` / 14`}
                </span>
              </InputAdornment>
            }
            type="number"
            placeholder={t("enterYourINN")}
          />
          {innErorr && (
            <FormHelperText>
              {t("enterYourINNCorrectly")}
            </FormHelperText>
          )}
        </FormControl>
        <div className="btnRow">
          <Button
            onClick={innViewFunc}
            disabled={innValue.length !== 14}
            fullWidth
            variant="contained"
            color="primary"
            disableElevation
          >
            {t("further")}
          </Button>
        </div>
      </div>
    );
  };
  const grsViewFunc = () => {
    innViewFunc();
    let subStr = passportNumberValue.substr(0, 2);
    if (
      subStr.toUpperCase() === "ID" ||
      subStr.toUpperCase() === "AN"
    ) {
      setLoading(true);
      RegistrationAPI.uploadGRSData(
        sessionIdReg,
        innValue,
        passportNumberValue.toUpperCase()
      )
        .then((data) => {
          setLoading(false);
          if (data.status === "fail") {
            alert(
              data.message ===
                "404 Result not found. Check login and password. Check pin, series and number"
                ? "Вы указали ИНН или номер паспорта с ошибкой"
                : data.message
            );
          }
          if (data.status === "success") {
            setGRSView(false);
            setRegGuestOpen(true);
          }
        })
        .catch(({ response }) => {
          setLoading(false);
          alert(response.data.message);
        });
    } else {
      setGRSError({
        innError: false,
        passportError: true,
        innMessage: "",
        passportMessage:
          passportNumberValue.length === 0
            ? t("enterYourPassportNumber")
            : t(
                "enterYourPassportNumberCorrectly"
              ),
      });
    }
  };
  const innViewWithPassportNumber = () => {
    return (
      <div
        className="authorization__form"
        style={{
          maxWidth: 296,
          margin: "0px auto",
        }}
      >
        <FormControl
          fullWidth
          className="authorization__pinInput"
          error={grsError.innError}
        >
          <Input
            inputProps={{
              maxLength: 14,
              autoComplete: "off",
              form: {
                autoComplete: "off",
              },
            }}
            value={innValue}
            onChange={(e) => {
              let value = e.target.value.trim();
              if (value.length <= 14) {
                if (
                  value.startsWith("1") ||
                  value.startsWith("2")
                ) {
                  setInnValue(value);
                } else {
                  setInnValue(
                    value.slice(
                      0,
                      value.length - 1
                    )
                  );
                }
              }
            }}
            endAdornment={
              <InputAdornment
                position="end"
                className="authorization__clear"
              >
                <span
                  style={{ color: "#c3c3c3" }}
                >
                  {innValue.length + ` / 14`}
                </span>
              </InputAdornment>
            }
            type="number"
            placeholder={t("enterYourINN")}
          />
          {grsError.innError && (
            <FormHelperText>
              {grsError.innMessage}
            </FormHelperText>
          )}
        </FormControl>
        <div
          style={{ width: "100%", height: 20 }}
        />
        <FormControl
          fullWidth
          className="authorization__pinInput"
          error={grsError.passportError}
        >
          <Input
            inputProps={{
              maxLength: 9,
              autoComplete: "off",
              form: {
                autoComplete: "off",
              },
            }}
            value={passportNumberValue}
            onChange={(event) => {
              let value = event.target.value
                .toString()
                .replaceAll(" ", "")
                .toUpperCase();
              setPassportNumberValue(
                value.startsWith("AN") ||
                  value.startsWith("ID") ||
                  value.length < 2
                  ? value
                  : value.slice(
                      0,
                      value.length - 2
                    )
              );
            }}
            endAdornment={
              <InputAdornment
                position="end"
                className="authorization__clear"
              >
                <span
                  style={{ color: "#c3c3c3" }}
                >
                  {passportNumberValue.length +
                    ` / 9`}
                </span>
              </InputAdornment>
            }
            type="text"
            placeholder={t("enterPassportNumber")}
          />
          {grsError.passportError && (
            <FormHelperText>
              {grsError.passportMessage}
            </FormHelperText>
          )}
        </FormControl>
        <div className="btnRow">
          <Button
            onClick={grsViewFunc}
            disabled={
              loading
                ? loading
                : innValue.length !== 14
                ? true
                : passportNumberValue.length !== 9
                ? true
                : false
            }
            startIcon={
              loading && (
                <CircularProgress size={26} />
              )
            }
            fullWidth
            variant="contained"
            color="primary"
            disableElevation
          >
            {t("further")}
          </Button>
        </div>
      </div>
    );
  };
  const pinCodeView = (timeLeft) => {
    let seconds = Math.floor(timeLeft % 60);

    return (
      <div className="authorization__form">
        <FormControl
          fullWidth
          className="authorization__pinInput"
          error={errorPinCode.error}
        >
          <Input
            inputProps={{
              maxLength: 4,
              autoComplete: "off",
            }}
            type="password"
            // placeholder="****"
            value={pinCode}
            onChange={(event) =>
              setPinCode(event.target.value)
            }
            startAdornment={
              <InputAdornment position="start">
                <span className="authorization__code">
                  Код
                </span>
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment
                position="end"
                className="authorization__clear"
              >
                <IconButton
                  onClick={() => setPinCode("")}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText>
            {errorPinCode.message}
          </FormHelperText>
        </FormControl>
        <Typography
          variant="body1"
          className={classes.label}
        >
          {t("codeSendedToNumber", {
            phone_number: phone
              .replace("+996 ", "0")
              .replaceAll("-", ""),
          })}
        </Typography>
        <Button
          className={classes.authorization__link}
          onClick={phoneClick}
          disabled={isBtnDisabled}
        >
          {`${t("getCodeAgain")} ${
            Boolean(seconds) ? seconds : ""
          }`}
        </Button>
        <div className="btnRow">
          <Button
            onClick={pinCodeClick}
            fullWidth
            variant="contained"
            color="primary"
            disableElevation
            disabled={
              pinCode.length === 4 ? false : true
            }
          >
            {t("further")}
          </Button>
        </div>
      </div>
    );
  };
  const confirmCloseClient = () => {
    if (window.confirm(t("confirmCancel"))) {
      window.location.reload();
    }
  };
  return (
    <>
      {!guestRegOpen ? (
        <div style={{ padding: 15 }}>
          <div
            onClick={confirmCloseClient}
            className="back"
          >
            <ArrowBackIosRoundedIcon />
            {t("cancel")}
          </div>
          <div className="title">
            {!confirm
              ? title
              : grsViewState
              ? t("enterYourPassportInfo")
              : t("enterCodeFromSMS")}
          </div>
          <div className="authorization__logo">
            {settings && (
              <img
                src={settings.logo}
                alt={settings.name}
              />
            )}
          </div>
          {!confirm
            ? !solve &&
              settings &&
              !innViewState &&
              settings.uniqueInterface
              ? innView()
              : phoneView()
            : grsViewState
            ? innViewWithPassportNumber()
            : pinCodeView(timeLeft)}
        </div>
      ) : (
        <PhotoFace guestPhotoFace={codeSuccess} />
      )}
      <Dialog
        open={regConfirm}
        onClose={() => setRegConfirm(false)}
      >
        <DialogTitle>
          <strong className="center">
            {t("thePhoneNumberIsNotRegistered", {
              phone_number: phone,
            })}
          </strong>
        </DialogTitle>
        {/* <DialogActions>
          <div className='justify-space-around'>
            <Button onClick={confirmClose} color='primary'>
              {t("cancel")}
            </Button>
            <Button onClick={registrationClick} color='primary' autoFocus>
              Регистрация
            </Button>
          </div>
        </DialogActions> */}
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  authorization__link: {
    color: "#007aff",
    textDecoration: "underline",
    height: "24px !important",
    marginBottom: 32,
    marginTop: 10,
    fontSize: "16px !important",
    borderRadius: "0px !important",
  },
  label: {
    fontSize: "1rem",
    color: "#333",
    textAlign: "center",
  },
}));

const mapStateToProps = (state: RootState) => ({
  settings: state.registration.settings,
  solve: state.registration.solve,
});

const mapDispatchToProps = (
  dispatch: Dispatch<Action> &
    ThunkDispatch<any, any, AnyAction>
) => ({
  startRegistrationAction: (
    regSessionId: string
  ) =>
    dispatch(
      actions.registration.startRegistrationAction(
        regSessionId
      )
    ),
  sessionIdAction: (sessionId: string) =>
    dispatch(
      actions.registration.sessionIdAction(
        sessionId
      )
    ),
  isAuthorizedAction: (isAuthorized: boolean) =>
    dispatch(
      actions.registration.isAuthorizedAction(
        isAuthorized
      )
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhoneEnter);
