import React from "react";

import SearchIcon from "../../icons/Search.svg";
import crossIcon from "../../icons/CrossBlack.svg";
import { IconButton, Paper, styled, TextField } from "@material-ui/core";

export default function SearchComponent({ placeholder, onChange, searchText }) {
  return (
    <Paper
      style={{ borderRadius: 6, padding: 24, marginTop: 20, marginBottom: 24 }}
      elevation={0}>
      <StyledTextField
        value={searchText}
        onChange={onChange}
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <img
              style={{ marginRight: 12 }}
              src={SearchIcon}
              alt="search icon"
            />
          ),
          endAdornment: Boolean(searchText.length) ? (
            <IconButton
              onClick={() =>
                onChange({
                  target: {
                    value: "",
                  },
                })
              }>
              <img src={crossIcon} alt="search icon" />
            </IconButton>
          ) : null,
        }}
        fullWidth
      />
    </Paper>
  );
}

export const StyledTextField = styled(TextField)(({ theme, props }) => ({
  backgroundColor: "#F1F2F3",
  borderRadius: 6,
  "& .MuiOutlinedInput-root": {
    height: 40,
    padding: "8px 12px",
    "& fieldset": {
      borderRadius: 6,
      border: "none",
    },
    "& input::placeholder": {
      color: "#AAAAAA",
      fontWeight: 400,
      opacity: 1,
    },
  },
}));
