import React, { useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store";
import { Box, Button, CircularProgress } from "@material-ui/core";
import { FacePasswordIcon } from "../../icons/icons";
import { RegistrationAPI } from "../../api/RegistrationAPI";
import { useHistory } from "react-router-dom";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import SnackbarAlert from "../bricks/SnackbarAlert";
import CalncelDilalog from "../bricks/CalncelDilalog";
import imageCompression from "browser-image-compression";
import PasswordBack from "./PasswordBack";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { useTranslation } from "react-i18next";
import Compress from "react-image-file-resizer";

type Props = ReturnType<typeof mapStateToProps> & {
  guestPasswordFace: boolean;
  setGuestPasswordFace: (value: boolean) => void;
};

const PasswordFace: React.FC<Props> = (props) => {
  let history = useHistory();
  const { regSessionId, guestPasswordFace, setGuestPasswordFace } = props;
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [imgUrl, setImgUrl] = useState<string | null>(null);
  const [notification, setNotification] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    error: false,
    message: "",
  });
  const [openCancel, setOpenCancel] = useState(false);
  const [guestPasswordBack, setGuestPasswordBack] = useState(false);
  const { t } = useTranslation();

  // const cencelClickOpen = () => {
  //     setOpenCancel(true);
  // }

  const cencelClickClose = () => {
    setOpenCancel(false);
  };

  const catchError = (message: string) => {
    setErrorMessage({ error: true, message: message });
    setLoading(false);
    setNotification(true);
  };

  const uploadPhotoChange = async (event: any) => {
    const imageFile = event.target.files[0];

    Compress.imageFileResizer(
      imageFile, // the file from input
      480, // width
      480, // height
      "JPEG", // compress format WEBP, JPEG, PNG
      70, // quality
      0, // rotation
      (compressedFile) => {
        setSelectedFile(compressedFile);
        setImgUrl(URL.createObjectURL(compressedFile));
        // You upload logic goes here
      },
      "blob" // blob or base64 default base64
    );
  };

  const uploadUserPhotoClick = () => {
    setLoading(true);
    let formData = new FormData();
    const passportVersion = "old";
    if (regSessionId && selectedFile) {
      formData.append("file", selectedFile!);
      RegistrationAPI.uploadPassportFront(
        regSessionId,
        passportVersion,
        formData
      )
        .then((data) => {
          if (data.status === "success") {
            setLoading(false);
            setNotification(false);
            if (guestPasswordFace) {
              setGuestPasswordBack(true);
            } else {
              return history.push("/password-back");
            }
          } else {
            catchError(data.message);
          }
        })
        .catch(({ response }) => catchError(response.data.message));
    }
  };
  return (
    <>
      {!guestPasswordBack ? (
        <div className="content passwordFace">
          <Box onClick={() => setGuestPasswordFace(false)}>
            <ArrowBackIosRoundedIcon />
          </Box>
          <div className="title">{t("passport_face")}</div>
          <p className="alert_text">
            {t("important")}
            <br />
            {t("beSureToKeep")}
            <br />
            {t("yourSmartphoneUpright")}
          </p>
          <div className="icon">
            {imgUrl ? <img src={imgUrl} alt="" /> : <FacePasswordIcon />}
          </div>
          <div className="btnRow">
            <div className="uploadPhoto">
              <input
                accept="image/*"
                id="contained-button-file"
                type="file"
                onChange={uploadPhotoChange}
                className="uploadPhoto__input"
              />
              <label htmlFor="contained-button-file">
                {imgUrl ? (
                  <Button
                    disableElevation
                    fullWidth
                    variant="contained"
                    className="orangeColor"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                    style={{ marginTop: 20 }}>
                    {t("pickAnotherPhoto")}
                  </Button>
                ) : (
                  <Button
                    disableElevation
                    fullWidth
                    variant="contained"
                    color="primary"
                    component="span"
                    startIcon={<CloudUploadIcon />}>
                    {t("addPhoto")}
                  </Button>
                )}
              </label>
            </div>
            {imgUrl && (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disableElevation
                disabled={loading}
                startIcon={loading && <CircularProgress size={26} />}
                onClick={uploadUserPhotoClick}
                style={{ marginBottom: 30 }}>
                {t("continue")}
              </Button>
            )}
          </div>
          <SnackbarAlert
            notification={notification}
            setNotification={setNotification}
            message={errorMessage.message}
            severity="error"
            vertical="top"
            horizontal="center"
          />
          <CalncelDilalog
            openCancel={openCancel}
            cencelClickClose={cencelClickClose}
            logout={true}
            backHistory="/"
          />
        </div>
      ) : (
        <PasswordBack guestPasswordBack={guestPasswordBack} />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  regSessionId: state.registration.regSessionId,
});

export default connect(mapStateToProps)(PasswordFace);
