import React, { Dispatch, useState, useEffect } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { Action, AnyAction } from "redux";
import { DocumentIcon, NextIcon } from "../../icons/icons";
import "moment/locale/ru";
import { ClientDocumentsAPI, IContract } from "../../api/ClientDocumentsAPI";
import ContractInfo from "../bricks/ContractInfo";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { IUserInfo } from "../../api/SessionAPI";
import Profile from "../Profile/Profile";
import moment from "moment";
import { uuidv4 } from "../../utils/generateId";
import { CircularProgress, useMediaQuery, useTheme } from "@material-ui/core";
import decode from "jwt-decode";
import { useTranslation } from "react-i18next";

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> & {
    userInfo: IUserInfo;
    bottom?: boolean;
  };

const Contracts: React.FC<Props> = (props) => {
  const {
    sessionId,
    userInfo,
    contractListOpenAction,
    regSessionId,
    bottom,
    signedDocumentInfoAction,
    setPdf,
  } = props;
  const [contracts, setContracts] = useState<IContract[]>([]);
  const [contract, setContract] = useState<IContract | null>(null);
  const [constractDetail, setConstractDetail] = useState(false);
  const [goBack, setGoBack] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  let session = sessionId || regSessionId;
  useEffect(() => {
    if (session) {
      setLoading(true);
      ClientDocumentsAPI.getDocuments(session, "Signed")
        .then((data) => {
          setContracts(data);
          setLoading(false);
        })
        .catch(({ response }) => console.error(response));
    }
  }, [session, userInfo.userId]);

  const constractClick = (contract: IContract) => {
    if (contract.documentType === "Pdf") {
      let apiUrl = localStorage.getItem("apiUrl"),
        url = `${apiUrl}/documents/GetDocumentPdf/${sessionId}/${contract.documentId}`;
      if (xs) {
        return window.open(url, "_blank");
      }
      props.setContract(contract);
      props.setContractDetail(true);
      return setPdf({
        mainDoc: {
          description: contract.documentDescription,
          url,
        },
      });
    }
    props.setContract(contract);
    props.setContractDetail(true);
    //@ts-ignore
    if (session) {
      ClientDocumentsAPI.getDocumentContracts(session, contract.documentId)
        .then((data) => {
          props.setDepartmentId(data.departmentId);
          data.documentSignature &&
            signedDocumentInfoAction(decode(data.documentSignature));
        })
        .catch(({ response }) => {
          console.error(response);
        });
    }
  };

  if (goBack) {
    contractListOpenAction(false);
    return <Profile userInfo={userInfo} />;
  }
  //@ts-ignore
  const data = contracts
    .sort((a, b) => {
      let textA = a.dateOfSigning;
      let textB = b.dateOfSigning;
      return textA > textB ? -1 : textA < textB ? 1 : 0;
    })
    .reduce((contract: any, elem: any) => {
      const date = moment(elem.dateOfSigning).format("DD MMMM YYYY");
      if (!contract[date]) {
        contract[date] = [];
      }
      contract[date].push(elem);
      return contract;
    }, {});
  const contractsGroup = Object.keys(data).map((date: string) => {
    return {
      date,
      idx: uuidv4(),
      contracts: data[date],
    };
  });
  return (
    <>
      {loading && (
        <div className='doc-preloader'>
          <CircularProgress />
        </div>
      )}
      {!constractDetail && (
        <div className='document'>
          <div className='cancel' onClick={() => setGoBack(true)}>
            <ArrowBackIosRoundedIcon />
          </div>
          <div className='title'>{t("menuDocs")}</div>
          {contractsGroup.length
            ? contractsGroup.map((constract) => {
                return (
                  <>
                    <div className='tabPanel__date'>{constract.date}</div>
                    {constract.contracts.map((item: any) => {
                      return (
                        <div
                          key={"doc-" + item.documentId}
                          className='documentBlock'
                          onClick={() => constractClick(item)}
                        >
                          <div className='documentBlock__icon'>
                            <DocumentIcon />
                          </div>
                          <p
                            className='documentBlock__title'
                            style={{
                              width: "calc(100% - 80px)",
                              overflowX: "hidden",
                            }}
                          >
                            {item.documentDescription}
                          </p>
                          <div className='documentBlock__endIcon'>
                            <NextIcon />
                          </div>
                        </div>
                      );
                    })}
                  </>
                );
              })
            : !loading && (
                <div className='empty-doc'>
                  {t("thereAreNoSignedDocuments")}
                </div>
              )}
        </div>
      )}
      {props.contractDetail && props.contract && (
        <ContractInfo
          departmentId={props.departmentId}
          html={props.contract.documentHtml}
          contract={props.contract}
          id={props.contract.documentId}
          userInfo={userInfo}
          setCurrentContract={setContract}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  sessionId: state.registration.sessionId,
  regSessionId: state.registration.regSessionId,
  contract: state.documents.contract,
  contractDetail: state.documents.contractDetail,
  departmentId: state.documents.departmentId,
});

const mapDispatchToProps = (
  dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
  contractListOpenAction: (contractListOpen: boolean) =>
    dispatch(actions.registration.contractListOpenAction(contractListOpen)),
  signedDocumentInfoAction: (documentSignedInfo: any) =>
    dispatch(actions.registration.signedDocumentInfoAction(documentSignedInfo)),
  setContract: (contract) => dispatch(actions.documents.setContract(contract)),
  setContractDetail: (contractDetail) =>
    dispatch(actions.documents.setContractDetail(contractDetail)),
  setDepartmentId: (departmentId) =>
    dispatch(actions.documents.setDepartmentId(departmentId)),
  setPdf: (pdf) => dispatch(actions.documents.setPdf(pdf)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contracts);
