import React, {useState} from 'react';
import {ChevronDown, ChevronUp, SignedIcon} from '../../icons/icons'
import {ClientDocumentsAPI} from '../../api/ClientDocumentsAPI'
import {LinearProgress} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import Moment from 'react-moment';
import decode from "jwt-decode";

const ChildDocuments = ({documentDescription, session, documentId, departmentId, documentSignedInfo, userInfo, signedDocumentInfoAction, contract }) => {
	const [open, setOpen] = useState(false)
	const [html, setHtml] = useState('')
	const [documentStatus, setDocumentStatus] = useState(null)
	const [departmentInfo, setDepartmentInfo] = useState(null);
	const [request, setRequest] = useState(false)
	const {t} = useTranslation()
	function createMarkup() {
		return {__html: html};
	}


	const getHtml = (open) => {
		if(open) {
			setRequest(true)
			ClientDocumentsAPI.getDocument(session, documentId).then(res => {

				// if (res.documentStatus === "New") {
					setHtml(res.documentHtml)
				// }

				setDocumentStatus(res.documentStatus)
	
				res.documentSignature && signedDocumentInfoAction(decode(res.documentSignature))

				ClientDocumentsAPI.getDepartmentDetails(session, res.departmentId).then(res => {
					setDepartmentInfo(res)
				})
				setRequest(false)
			}).catch(({response}) => {
				console.error(response)
				setRequest(false)
			});
		}
	}
	return (
		<div className="child-documents__item">
			<div className="child-documents__wrap">
				<div className="child-documents__name">{documentDescription}</div>
				<div onClick={() => {
					getHtml(!false)
					setOpen(!open)
				}}>{!open ? <ChevronDown/> : <ChevronUp/>}</div>
			</div>
			{request ?       <LinearProgress />
 : 				open && <><div className="child-documents__html" dangerouslySetInnerHTML={createMarkup()} />

 					{documentStatus === "Signed" && departmentInfo &&
            <div className="signedContract" style={{bottom: "12px" }}>
                <div className="signedContract__item">
                    {departmentInfo.departmentName &&
                    <div className="signedContract__label">{t('organization')}: <strong>{departmentInfo.departmentName}</strong>
                    </div>}
                    {departmentInfo.departmentHeadFullName && <div
                        className="signedContract__label">{t('employee')}: <strong>{departmentInfo.departmentHeadFullName}</strong>
                    </div>}
                </div>
                <div className="signedContract__item">
                    <div className="signedContract__label">{t('client')}: <strong>{userInfo.fullName}</strong></div>
                </div>
                <div className="signed__signature">
                    {t('signed')}: <span><Moment format="DD.MM.YYYY">{contract.createdDate}</Moment></span>  <span><Moment
                    format="hh:mm:ss">{contract.createdDate}</Moment></span>
                </div>
                <div className="signed__item">
                    <div className="signed__icon"><SignedIcon/></div>
                    {documentSignedInfo ? documentSignedInfo.signatures[0].signatureInfo : t('signedWithQualifiedDigitalSign')}
                </div>
            </div>}
						</>
 
 }

 	
		</div>
	);
};

export default ChildDocuments;
