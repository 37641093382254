import React, { Dispatch, useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { Action, AnyAction } from "redux";
// import { baseUrl } from '../../utils/baseUrl';
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import {
  Button,
  FormControlLabel,
  Checkbox,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import ConfirmModal from "./ConfirmModal";
import { ClientDocumentsAPI, IContract } from "../../api/ClientDocumentsAPI";
import { SignedIcon } from "../../icons/icons";
import Moment from "react-moment";
import "moment/locale/ru";
import { IUserInfo } from "../../api/SessionAPI";
// import Contracts from './Contracts';
import decode from "jwt-decode";
import ChildDocuments from "../ChildDocuments/ChildDocuments";
import { useTranslation } from "react-i18next";
import { ChevronDown, ChevronUp } from "../../icons/icons";

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> & {
    contract: IContract;
    html?: any;
    pdf?: any;
    userInfo: IUserInfo;
    setCurrentContract?: React.Dispatch<React.SetStateAction<IContract | null>>;
    document?: any;
    departmentInfo?: any;
    id?: any;
    departmentId?: number | null;
    bottom?: boolean;
    childDocuments?: any;
    currentContract?: any;
    mainDoc?: any;
  };

const ContractInfo: React.FC<Props> = (props) => {
  const {
    sessionId,
    id,
    childDocuments,
    departmentId,
    documentSignedInfo,
    signedDocumentInfoAction,
    regSessionId,
    document,
    html,
    pdf,
    contract,
    userInfo,
    setCurrentContract,
    tabValueAction,
    contractListOpenAction,
  } = props;
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openConfirmReject, setOpenConfirmReject] = useState(false);
  const [documentStatus, setDocumentStatus] = useState(contract.documentStatus);
  const [agree, setAgree] = useState(false);
  const [goBack, setGoBack] = useState(false);
  const [departmentInfo, setDepartmentInfo] = useState<any>(null);
  const { t } = useTranslation();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  let session = sessionId || regSessionId;
  useEffect(() => {
    if (session) {
      if (documentStatus === "Signed") {
        ClientDocumentsAPI.getDocument(session, id)
          .then((data) => {
            data.documentSignature &&
              signedDocumentInfoAction(decode(data.documentSignature));
          })
          .catch(({ response }) => {
            console.error(response);
          });
      }
    }
  }, [signedDocumentInfoAction, documentStatus, session, id]);
  useEffect(() => {
    if (documentStatus !== contract.documentStatus) {
      setDocumentStatus(contract.documentStatus);
      setAgree(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract]);
  useEffect(() => {
    if (
      session &&
      (document?.eventParameters.documentType !== "Pdf" ||
        contract?.documentType !== "Pdf")
    ) {
      if (departmentId) {
        ClientDocumentsAPI.getDepartmentDetails(session, departmentId).then(
          (res) => {
            setDepartmentInfo(res);
          }
        );
      }
    }
  }, [departmentId, documentStatus, session, document, contract]);
  const confirmClickOpen = () => {
    setOpenConfirm(true);
  };

  const confirmClickClose = () => {
    setOpenConfirm(false);
  };

  const confirmClickOpenReject = () => {
    setOpenConfirmReject(true);
  };

  const confirmClickCloseReject = () => {
    setOpenConfirmReject(false);
  };

  if (goBack) {
    if (setCurrentContract) {
      props.setContract(null);
      props.setContractConfirm(null);
      setCurrentContract(null);
      tabValueAction(0);
      contractListOpenAction(true);
    }
    // return <Contracts userInfo={userInfo}/>
  }

  return (
    <div className='contractInfo'>
      <div className='contractInfo_wrapper'>
        {documentStatus !== "New" && (
          <div
            className='cancel'
            onClick={() => {
              // setGoBack(true)
              props.setContract(null);
              props.setContractDetail(null);
              props.setDepartmentId(null);
              props.setContractConfirm(null);
              setCurrentContract(null);
            }}
          >
            <ArrowBackIosRoundedIcon />
          </div>
        )}
        {contract?.documentType !== "Pdf" && (
          <>
            <h2>
              {props.currentContract &&
                props.currentContract.documentDescription}
            </h2>
          </>
        )}

        <div className='contractWrapper'>
          {(document?.eventParameters.documentType === "Pdf" ||
            contract?.documentType === "Pdf") &&
          Boolean(pdf?.mainDoc) ? (
            <>
              <h2 style={{ margin: "20px 0px" }}>{pdf?.mainDoc.description}</h2>
              {xs ? (
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  disableElevation
                  onClick={() => window.open(pdf?.mainDoc.url, "_blank")}
                >
                  {t("loadFile")}
                </Button>
              ) : (
                <iframe
                  title='generated'
                  width='100%'
                  style={{ height: "100vh" }}
                  src={pdf?.mainDoc.url}
                ></iframe>
              )}
            </>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: html }}></div>
          )}
          {documentStatus === "Signed" && departmentInfo && (
            <div className='signedContract' style={{ bottom: "12px" }}>
              <div className='signedContract__item'>
                {departmentInfo.departmentName && (
                  <div className='signedContract__label'>
                    {t("organization")}:{" "}
                    <strong>{departmentInfo.departmentName}</strong>
                  </div>
                )}
                {departmentInfo.departmentHeadFullName && (
                  <div className='signedContract__label'>
                    {t("employee")}:{" "}
                    <strong>{departmentInfo.departmentHeadFullName}</strong>
                  </div>
                )}
              </div>
              <div className='signedContract__item'>
                <div className='signedContract__label'>
                  {t("client")}: <strong>{userInfo.fullName}</strong>
                </div>
              </div>
              <div className='signed__signature'>
                {t("signed")}:{" "}
                <span>
                  <Moment format='DD.MM.YYYY'>{contract.createdDate}</Moment>
                </span>{" "}
                <span>
                  <Moment format='hh:mm:ss'>{contract.createdDate}</Moment>
                </span>
              </div>
              <div className='signed__item'>
                <div className='signed__icon'>
                  <SignedIcon />
                </div>
                {documentSignedInfo
                  ? documentSignedInfo.signatures[0].signatureInfo
                  : t("signedWithQualifiedDigitalSign")}
              </div>
            </div>
          )}
          <div className='child-documents'>
            {Boolean(childDocuments) &&
              childDocuments.map((x: any, i: number) => (
                <ChildDocuments
                  userInfo={userInfo}
                  contract={contract}
                  signedDocumentInfoAction={signedDocumentInfoAction}
                  session={sessionId}
                  {...x}
                />
              ))}
            {Boolean(pdf?.childDocuments) &&
              document.eventParameters.documentType === "Pdf" &&
              pdf.childDocuments.map((x: any, i: number) => (
                <PdfChildDoc pdf={x} />
              ))}
          </div>
        </div>

        {documentStatus !== "Signed" && (
          <div className='btnRow'>
            <FormControlLabel
              control={
                <Checkbox
                  checked={agree}
                  onChange={(event) => setAgree(event.target.checked)}
                  name='checkedB'
                  color='primary'
                />
              }
              label={t("agreeTerms")}
            />
            <Button
              fullWidth
              variant='contained'
              color='primary'
              disableElevation
              disabled={!agree}
              onClick={confirmClickOpen}
            >
              {t("sign")}
            </Button>
            <Button
              fullWidth
              variant='contained'
              color='secondary'
              disableElevation
              onClick={confirmClickOpenReject}
            >
              {t("reject")}
            </Button>
          </div>
        )}

        <ConfirmModal
          openConfirm={openConfirm}
          confirmClickClose={confirmClickClose}
          title={t("confirmation")}
          text={t("signConfirmation")}
          buttonText={t("confirm")}
          cinfirm={true}
          documentId={document && document.eventParameters.documentId}
          setDocumentStatus={setDocumentStatus}
          setGoBack={setGoBack}
        />
        <ConfirmModal
          openConfirm={openConfirmReject}
          confirmClickClose={confirmClickCloseReject}
          title={t("application")}
          text={t("signRejection")}
          buttonText={t("yes")}
          cinfirm={false}
          documentId={document && document.eventParameters.documentId}
          setDocumentStatus={setDocumentStatus}
          setGoBack={setGoBack}
        />
      </div>{" "}
    </div>
  );
};

function PdfChildDoc({ pdf }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const [open, setOpen] = useState(false);

  return (
    <div className='child-documents__item' style={{ margin: "30px 0px" }}>
      <div
        className='child-documents__wrap'
        style={{ cursor: "pointer", flexDirection: xs ? "column" : "row" }}
        onClick={() => {
          if (!xs) {
            setOpen(!open);
          }
        }}
      >
        {xs ? (
          <h2 style={{ margin: "20px 0px" }}>{pdf.description}</h2>
        ) : (
          <div className='child-documents__name'>{pdf.description}</div>
        )}
        {!xs && <div>{!open ? <ChevronDown /> : <ChevronUp />}</div>}

        {xs && (
          <Button
            fullWidth
            variant='contained'
            color='primary'
            disableElevation
            onClick={() => window.open(pdf.url, "_blank")}
          >
            {t("loadFile")}
          </Button>
        )}
      </div>

      {open && !xs && (
        <iframe
          src={pdf.url}
          width='100%'
          title={`generatedChildPdf: ${pdf.name}`}
          style={{ height: "100vh" }}
        ></iframe>
      )}
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  sessionId: state.registration.sessionId,
  settings: state.registration.settings,
  regSessionId: state.registration.regSessionId,
  documentSignedInfo: state.registration.documentSignedInfo,
  currentContract: state.documents.contract,
  pdf: state.documents.pdf,
});

const mapDispatchToProps = (
  dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
  tabValueAction: (tabValue: number) =>
    dispatch(actions.registration.tabValueAction(tabValue)),
  contractListOpenAction: (contractListOpen: boolean) =>
    dispatch(actions.registration.contractListOpenAction(contractListOpen)),
  signedDocumentInfoAction: (documentSignedInfo: any) =>
    dispatch(actions.registration.signedDocumentInfoAction(documentSignedInfo)),
  setContract: (contract) => dispatch(actions.documents.setContract(contract)),
  setContractDetail: (contractDetail) =>
    dispatch(actions.documents.setContractDetail(contractDetail)),
  setDepartmentId: (departmentId) =>
    dispatch(actions.documents.setDepartmentId(departmentId)),
  setContractConfirm: (contract) =>
    dispatch(actions.documents.setContractConfirm(contract)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractInfo);
