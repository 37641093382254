import React, { Dispatch, useState } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { Action, AnyAction } from "redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import {
  ClientDocumentsAPI,
  DocumentStatus,
} from "../../api/ClientDocumentsAPI";
import { useTranslation } from "react-i18next";
import SnackbarAlert from "./SnackbarAlert";

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> & {
    openConfirm: boolean;
    confirmClickClose: () => void;
    text: string;
    buttonText: string;
    cinfirm: boolean;
    title: string;
    documentId: string;
    setDocumentStatus: React.Dispatch<React.SetStateAction<DocumentStatus>>;
    setGoBack?: React.Dispatch<React.SetStateAction<boolean>>;
  };

const ConfirmModal: React.FC<Props> = (props) => {
  const {
    sessionId,
    openConfirm,
    confirmClickClose,
    regSessionId,
    text,
    buttonText,
    cinfirm,
    title,
    documentId,
    documentStatusSuccessAction,
    documentStatusSuccess,
    setDocumentStatus,
    setGoBack,
  } = props;
  const [notification, setNotification] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    error: false,
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const catchError = (message: string, status: number) => {
    setNotification(true);
    if (status === 404) {
      setErrorMessage({
        error: true,
        message: "Вы уже одобрили или отклонили ранее",
      });
    } else {
      setErrorMessage({ error: true, message });
    }
    confirmClickClose();
  };

  const confirmClick = () => {
    setLoading(true);
    let session = sessionId || regSessionId;
    if (session) {
      if (cinfirm) {
        ClientDocumentsAPI.signDocument(session, documentId)
          .then((data) => {
            setLoading(false);
            if (data.success === true) {
              documentStatusSuccessAction(!documentStatusSuccess);
              setDocumentStatus(DocumentStatus.Signed);
              setNotification(true);
              confirmClickClose();
              setErrorMessage({
                error: false,
                message: "Вы успешно одобрили документ!",
              });
              setTimeout(() => setGoBack(true), 2000);
            }
          })
          .catch(({ response }) => {
            catchError(response.data.Message, response.data.status);
            setLoading(false);
          });
      } else {
        ClientDocumentsAPI.refuseDocument(session, documentId)
          .then((data) => {
            setLoading(false);
            if (data.success === true) {
              documentStatusSuccessAction(!documentStatusSuccess);
              setDocumentStatus(DocumentStatus.Refused);
              setNotification(true);
              confirmClickClose();
              setErrorMessage({
                error: false,
                message: "Вы успешно отклонили документ!",
              });
              setGoBack && setGoBack(true);
            }
          })
          .catch(({ response }) => {
            catchError(response.data.Message, response.data.status);
            setLoading(false);
          });
      }
    }
  };
  return (
    <>
      {loading && (
        <div className='main-preloader'>
          <div className='pulse'></div>
        </div>
      )}
      {!loading && (
        <Dialog open={openConfirm} className='modal confirm'>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <p className='modal__text'>{text}</p>
          </DialogContent>
          <DialogActions className='modal__actions'>
            <Button onClick={confirmClickClose} color='primary'>
              {t("cancel")}
            </Button>
            <Button
              onClick={confirmClick}
              variant='contained'
              color={cinfirm ? "primary" : "secondary"}
              disableElevation
            >
              {buttonText}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <SnackbarAlert
        notification={notification}
        setNotification={setNotification}
        message={errorMessage.message}
        severity={errorMessage.error ? "error" : "success"}
        vertical='top'
        horizontal='center'
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  sessionId: state.registration.sessionId,
  documentStatusSuccess: state.registration.documentStatusSuccess,
  regSessionId: state.registration.regSessionId,
});

const mapDispatchToProps = (
  dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
  documentStatusSuccessAction: (documentStatusSuccess: boolean) =>
    dispatch(
      actions.registration.documentStatusSuccessAction(documentStatusSuccess)
    ),
  tabValueAction: (tabValue: number) =>
    dispatch(actions.registration.tabValueAction(tabValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal);
