import React from "react";
import {
  Button,
  IconButton,
} from "@material-ui/core";
import { VideoIcon } from "../../icons/icons";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { useTranslation } from "react-i18next";

const VideoCall = () => {
  const { t } = useTranslation();

  return (
    <div className="content videoCall awaitingOperator">
      <IconButton
        onClick={() => window.location.reload()}
        className="prevButton">
        <ArrowBackIosRoundedIcon />
      </IconButton>
      <div className="title">
        {t("waitOperator")}
      </div>
      <div className="icon">
        <VideoIcon />
      </div>
      <div className="videoCall__round"></div>
      <div className="videoCall__btnRow">
        <Button
          variant="outlined"
          color="secondary"
          onClick={() =>
            window.location.reload()
          }>
          {t("cancelVideocall")}
        </Button>
      </div>
    </div>
  );
};

export default VideoCall;
