import React, { Dispatch, useState } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { Action, AnyAction } from "redux";
import { SolveCameraIcon, SolveMicrofonIcon } from "../../icons/icons";
import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import Countdown, { CountdownRenderProps } from "react-countdown-now";
import { useTranslation } from "react-i18next";
import accessAudioKg from "../../img/access_kg.mp3";
import accessAudioRu from "../../img/access_ru.mp3";
import platform from "platform";

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

const Solve: React.FC<Props> = ({ solveAction }) => {
  const { i18n, t } = useTranslation();
  const accessAudio = React.useRef(null);
  const [openConfirmAudioPlayer, setConfirmAudioPlayer] = useState(false);

  React.useEffect(() => {
    let os = platform.os;

    if (os.family !== "iOS") {
      accessAudio!.current!.play();
    } else {
      setConfirmAudioPlayer(true);
    }
  }, [accessAudio]);

  const renderer = (props: CountdownRenderProps) => {
    const { seconds, completed } = props;
    return (
      <>
        <Dialog
          open={openConfirmAudioPlayer}
          onClose={() => setConfirmAudioPlayer(false)}>
          <DialogTitle>{t("pleaseListenToNotification")}</DialogTitle>
          <DialogActions style={{ textAlign: "center" }}>
            <Button
              onClick={() => {
                accessAudio!.current!.play();
                setConfirmAudioPlayer(false);
              }}
              variant="contained"
              color="primary"
              disableElevation>
              {t("listen")}
            </Button>
          </DialogActions>
        </Dialog>

        <audio
          ref={accessAudio}
          src={i18n.language === "ru" ? accessAudioRu : accessAudioKg}></audio>
        {completed ? (
          <div className="solve">
            <div className="solve__content">
              <h2>{t("alert")}</h2>
              <p>{t("dearCustomer")},</p>
              <p>
                {t("systemRequestAccessTo")} <br /> {t("toCameraAndMicro")}
              </p>
              <div className="solve__row">
                <div className="solve__icon">
                  <SolveCameraIcon />
                </div>
                <div className="solve__icon">
                  <SolveMicrofonIcon />
                </div>
              </div>
              <p className="bold">{t("pressAllowButton")}</p>
            </div>
            <div className="solve__btn">
              <Button
                onClick={() => solveAction(true)}
                fullWidth
                variant="contained"
                color="primary"
                disableElevation>
                {t("understood")}
              </Button>
            </div>
          </div>
        ) : (
          <div className="solve">
            <div className="solve__content">
              <h2>{t("alert")}</h2>
              <p>{t("dearCustomer")},</p>
              <p>
                {t("systemRequestAccessTo")} <br /> {t("toCameraAndMicro")}
              </p>
              <div className="solve__row">
                <div className="solve__icon">
                  <SolveCameraIcon />
                </div>
                <div className="solve__icon">
                  <SolveMicrofonIcon />
                </div>
              </div>
              <p className="bold">{t("pressAllowButton")}</p>
            </div>
            <div className="solve__btn">
              <Button
                onClick={() => solveAction(true)}
                fullWidth
                variant="contained"
                color="primary"
                disabled
                disableElevation>
                {t("understood")} ({seconds})
              </Button>
            </div>
          </div>
        )}
      </>
    );
  };

  return <Countdown date={Date.now() + 10000} renderer={renderer} />;
};

const mapStateToProps = (state: RootState) => ({
  settings: state.registration.settings,
});

const mapDispatchToProps = (
  dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
  solveAction: (solve: boolean) =>
    dispatch(actions.registration.solveAction(solve)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Solve);
