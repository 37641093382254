import React, { useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store";
import { Button, CircularProgress } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { RegistrationAPI } from "../../api/RegistrationAPI";
import { useHistory } from "react-router-dom";
import { FacePersonIcon } from "../../icons/icons";
import SnackbarAlert from "../bricks/SnackbarAlert";
import CalncelDilalog from "../bricks/CalncelDilalog";
import imageCompression from "browser-image-compression";
import PasswordFace from "./PasswordFace";
import { useTranslation } from "react-i18next";
import Compress from "react-image-file-resizer";

type Props = ReturnType<typeof mapStateToProps> & {
  guestPhotoFace: boolean;
};

const PhotoFace: React.FC<Props> = (props) => {
  let history = useHistory();
  const { regSessionId, guestPhotoFace } = props;
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [imgUrl, setImgUrl] = useState<string | null>(null);
  const [notification, setNotification] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    error: false,
    message: "",
  });
  const [openCancel, setOpenCancel] = useState(false);
  const [guestPasswordFace, setGuestPasswordFace] = useState(false);
  const { t } = useTranslation();

  const cencelClickOpen = () => {
    setOpenCancel(true);
  };

  const cencelClickClose = () => {
    setOpenCancel(false);
  };

  const catchError = (message: string) => {
    setErrorMessage({ error: true, message: message });
    setLoading(false);
    setNotification(true);
  };

  const uploadPhotoChange = async (event: any) => {
    const imageFile = event.target.files[0];
    Compress.imageFileResizer(
      imageFile, // the file from input
      480, // width
      480, // height
      "JPEG", // compress format WEBP, JPEG, PNG
      70, // quality
      0, // rotation
      (compressedFile) => {
        setSelectedFile(compressedFile);
        setImgUrl(URL.createObjectURL(compressedFile));
        // You upload logic goes here
      },
      "blob" // blob or base64 default base64
    );
  };

  const uploadUserPhotoClick = () => {
    setLoading(true);
    let formData = new FormData();
    if (regSessionId && selectedFile) {
      formData.append("file", selectedFile!);
      RegistrationAPI.uploadUserPhoto(regSessionId, formData)
        .then((data) => {
          if (data.status === "success") {
            setLoading(false);
            setNotification(false);
            if (guestPhotoFace) {
              setGuestPasswordFace(true);
            } else {
              return history.push("/password-face");
            }
          } else {
            catchError(data.message);
          }
        })
        .catch(({ response }) => catchError(response.data.message));
    }
  };

  return (
    <>
      {!guestPasswordFace ? (
        <div className="content photoFace">
          <Button className="cancel" color="primary" onClick={cencelClickOpen}>
            {t("cancel")}
          </Button>
          <div className="title">{t("addFacePhoto")}</div>
          <div className="icon">
            {imgUrl ? (
              <div
                style={{ backgroundImage: `url(${imgUrl})` }}
                className="icon__img"
              />
            ) : (
              <FacePersonIcon />
            )}
          </div>
          <div className="btnRow" style={{ marginTop: 10 }}>
            <div className="uploadPhoto">
              <input
                accept="image/*"
                id="contained-button-file"
                type="file"
                onChange={uploadPhotoChange}
                className="uploadPhoto__input"
              />
              <label htmlFor="contained-button-file">
                {imgUrl ? (
                  <Button
                    disableElevation
                    fullWidth
                    variant="contained"
                    className="orangeColor"
                    component="span"
                    startIcon={<CloudUploadIcon />}>
                    {t("pickAnotherPhoto")}
                  </Button>
                ) : (
                  <Button
                    disableElevation
                    fullWidth
                    variant="contained"
                    color="primary"
                    component="span"
                    startIcon={<CloudUploadIcon />}>
                    {t("addPhoto")}
                  </Button>
                )}
              </label>
            </div>
            {imgUrl && (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disableElevation
                disabled={loading}
                startIcon={loading && <CircularProgress size={26} />}
                onClick={uploadUserPhotoClick}>
                {t("continue")}
              </Button>
            )}
          </div>
          <SnackbarAlert
            notification={notification}
            setNotification={setNotification}
            message={errorMessage.message}
            severity="error"
            vertical="top"
            horizontal="center"
          />
          <CalncelDilalog
            logout={true}
            openCancel={openCancel}
            cencelClickClose={cencelClickClose}
            backHistory="/"
          />
        </div>
      ) : (
        <PasswordFace
          setGuestPasswordFace={setGuestPasswordFace}
          guestPasswordFace={guestPasswordFace}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  regSessionId: state.registration.regSessionId,
});

export default connect(mapStateToProps)(PhotoFace);
