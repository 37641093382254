const defaultState = {
  contract: null,
  contractDetail: false,
  departmentId: null,
  html: null,
  pdf: null,
  contractConfirm: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case "SET_CONTRACT":
      return {
        ...state,
        contract: action.contract,
      };
    case "SET_CONTRACT_DETAIL":
      return {
        ...state,
        contractDetail: action.contractDetail,
      };
    case "SET_DEPARTMENT_ID":
      return {
        ...state,
        departmentId: action.departmentId,
      };
    case "SET_HTML":
      return {
        ...state,
        html: action.html,
      };
    case "SET_PDF":
      return {
        ...state,
        pdf: action.pdf,
      };
    case "SET_CONTRACT_CONFIRM":
      return {
        ...state,
        contractConfirm: action.contract,
      };
    default:
      return state;
  }
};
