import { InstanceDocumentHead, InstanceHead } from "./InstanceHead";

export class ClientDocumentsAPI {
  public static async getPaymentOrders(
    sessionId: string,
    clientAccountId: number
  ) {
    return await InstanceHead.instance
      .get(`ClientDocuments/GetPaymentOrders/${sessionId}/${clientAccountId}`)
      .then((res) => {
        return res.data;
      });
  }

  public static async addPaymentOrder(
    sessionId: string,
    clientAccountId: number,
    paymentOrder: IAddPaymentOrder
  ) {
    return await InstanceHead.instance
      .post(
        `ClientDocuments/AddPaymentOrder/${sessionId}/${clientAccountId}`,
        paymentOrder
      )
      .then((res) => {
        return res.data;
      });
  }

  // public static async signDocument(sessionId: string, documentId: string) {
  //     return await InstanceHead.instance.post(`ClientDocuments/SignDocument/${sessionId}/${documentId}`).then(res => {
  //         return res.data;
  //     })
  // }

  // public static async refuseDocument(sessionId: string, documentId: string) {
  //     return await InstanceHead.instance.post(`ClientDocuments/RefuseDocument/${sessionId}/${documentId}`).then(res => {
  //         return res.data;
  //     })
  // }

  public static async getContracts(sessionId: string, clientAccountId: number) {
    return await InstanceHead.instance
      .get(`ClientDocuments/GetContracts/${sessionId}/${clientAccountId}`)
      .then((res) => {
        return res.data;
      });
  }

  public static async getContract(
    sessionId: string,
    documentId: string,
    apiKey: string
  ) {
    return await InstanceHead.instance
      .get(`ClientDocuments/GetContract/${sessionId}/${documentId}/${apiKey}`)
      .then((res) => {
        return res.data;
      });
  }

  public static async getDocument(sessionId: string, documentId: number | any) {
    return await InstanceDocumentHead.instance
      .get(`/documents/GetDocument/${sessionId}/${documentId}`)
      .then((res) => {
        return res.data;
      });
  }

  public static async getDocumentContracts(
    sessionId: string,
    documentId: string
  ) {
    return await InstanceDocumentHead.instance
      .get(`/documents/GetDocument/${sessionId}/${documentId}`)
      .then((res) => {
        console.log(res);

        return res.data;
      });
  }

  public static async getDepartmentDetails(
    sessionId: string,
    departmentId: number | undefined | null
  ) {
    return await InstanceDocumentHead.instance
      .get(`/references/GetDepartment/${sessionId}/${departmentId}`)
      .then((res) => {
        return res.data;
      });
  }

  public static async getDocuments(
    sessionId: string,
    documentStatus: string,
    documentType = ""
  ) {
    return await InstanceDocumentHead.instance
      .post(`/documents/GetDocuments/${sessionId}`, {
        documentStatus,
        documentType,
      })
      .then((res) => {
        return res.data;
      });
  }

  public static async signDocument(sessionId: string, documentId: string) {
    return await InstanceDocumentHead.instance
      .post(`/documents/SignDocument/${sessionId}/${documentId}`)
      .then((res) => {
        return res.data;
      });
  }

  public static async refuseDocument(sessionId: string, documentId: string) {
    return await InstanceDocumentHead.instance
      .post(`/documents/RefuseDocument/${sessionId}/${documentId}`)
      .then((res) => {
        return res.data;
      });
  }
}

export interface IAddPaymentOrder {
  paymentOrderNumber: string;
  description: string;
  date: Date;
  payer: {
    accountOfBank: string;
    calculationScheme: string;
  };
  paymentRecipient: {
    name: string;
    bank: string;
    bik: string;
    paymentAccount: string;
    target: string;
    paymentCode: string;
    amount: number;
    currency: string;
  };
}

export interface IPaymentOrders {
  documentId: string;
  createdDate: Date;
  userAccountId: number;
  documentStatus: DocumentStatus;
  document: {
    date: Date;
    payer: {
      accountOfBank: string;
      calculationScheme: string;
    };
    paymentRecipient: {
      name: string;
      bank: string;
      bik: string;
      paymentAccount: string;
      target: string;
      paymentCode: string;
      amount: number;
      currency: string;
    };
  };
  documentJson: any;
}

export interface IContract {
  documentId: string;
  documentType: DocumentTypes;
  createdDate: Date;
  userAccountId: 0;
  documentStatus: DocumentStatus;
  documentDescription: string;
  documentHtml: string;
  dateOfSigning: string;
  departmentId?: number;
}

export enum DocumentTypes {
  PaymentOrder = "PaymentOrder",
  ClientContract = "ClientContract",
  Pdf = "Pdf",
}

export enum DocumentStatus {
  New = "New",
  Signed = "Signed",
  Refused = "Refused",
}
