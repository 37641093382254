import React, {
  Dispatch,
  useState,
  useEffect,
} from "react";
import { connect } from "react-redux";
import { RootState } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { Action, AnyAction } from "redux";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  MenuItem,
  Popover,
  makeStyles,
  Typography,
  Icon,
} from "@material-ui/core";
import { QuestionIcon } from "../../icons/icons";
import { useHistory } from "react-router-dom";
// import {IConfirmUser} from '../../store/Registration/types';
import CalncelDilalog from "../bricks/CalncelDilalog";
import { RegistrationAPI } from "../../api/RegistrationAPI";
import SnackbarAlert from "../bricks/SnackbarAlert";
import InputMask from "react-input-mask";
import RegistrationEnd from "./RegistrationEnd";
import NewPassword from "../../img/newPassword.jpg";
import OldPassword from "../../img/old_passport.png";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { useTranslation } from "react-i18next";
import * as translit from "@hqdaemon/translit";
import InfiniteScroll from "../Client/InfiniteScroll";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

type Props = ReturnType<
  typeof mapDispatchToProps
> &
  ReturnType<typeof mapStateToProps> & {
    guestPasswordData: boolean;
  };

const PasswordData: React.FC<Props> = (props) => {
  const {
    regSessionId,
    guestPasswordData = true,
    settings,
  } = props;
  let history = useHistory();
  const [confirm, setConfirm] = useState(false);
  const [confirmUser, setConfirmUser] = useState({
    dateBirth: "",
    dateExpiry: "",
    dateIssue: "",
    authority: "",
    inn: "",
    email: "",
    passportNumber: "",
    actualPlaceOfResidence: "",
    name: "",
    nameLat: "",
    existenceOfBeneficialOwner: false,
    availabilityOfCitizenshipOrGreenCardUSA:
      false,
    surname: "",
    surnameLat: "",
    nation: "",
    familyStatus: "",
    post: "",
    placeOfWork: "",
    codeWord: "",
    patronymic: "",
    userDidNotChangeData: true,
    registrationAddress: "",
    isPolitic: false,
    stateEmployee: false,
    residenceAddress: {
      residenceCityId: 0,
      residenceCity: "",
      residenceStreet: "",
      residenceHouse: "",
      residenceFlat: "",
      fullAddress: "",
    },
  });

  const [openCancel, setOpenCancel] =
    useState(false);
  const [notification, setNotification] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [btnDisabled, setDisabled] =
    useState(true);
  const [errorMessage, setErrorMessage] =
    useState({
      error: false,
      message: "",
    });
  const [
    guestRegistrationEnd,
    setGuestRegistrationEnd,
  ] = useState(false);
  const [personalNumber, setPersonalNumber] =
    useState(false);
  const [citiesData, setCitiesData] = useState(
    []
  );
  const [citiesListSelect, setCitiesListSelect] =
    useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (regSessionId) {
      RegistrationAPI.getUserRegistrationInfo(
        regSessionId
      )
        .then((data) => {
          setLoading(false);
          if (data.status === "success") {
            setConfirmUser((state) => ({
              ...state,
              ...data.result,
              residenceAddress: {
                residenceCityId: 0,
                residenceCity: "",
                residenceStreet: "",
                residenceHouse: "",
                residenceFlat: "",
                fullAddress:
                  data.result.residenceAddress
                    .fullAddress,
              },
            }));
          }
        })
        .catch(({ response }) =>
          catchError(response.data.message)
        );
    }
  }, [regSessionId, setConfirmUser]);
  useEffect(() => {
    if (citiesData.length === 0) {
      fetch(
        "https://smartidkg.onoi.kg/api/Reg/GetAllCities"
      )
        .then((response) =>
          response.json().then((data) => {
            setCitiesData(data);
            setCitiesListSelect(data);
          })
        )
        .catch((error) => {
          setErrorMessage({
            error: true,
            message:
              "Не удалось закгрузить список городов",
          });
          setNotification(true);
        });
    }
  });

  const catchError = (message: string) => {
    setErrorMessage({
      error: true,
      message: message,
    });
    setLoading(false);
    setNotification(true);
    setConfirm(false);
  };

  const cencelClickOpen = () => {
    setOpenCancel(true);
  };

  const cencelClickClose = () => {
    setOpenCancel(false);
  };
  const confirmUserChange =
    (fieldName: keyof any) =>
    (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      if (fieldName === "surname") {
        setConfirmUser({
          ...confirmUser,
          [fieldName]: event.target.value,
          surnameLat: translit(
            event.target.value
          ), // важно!
        });
      } else if (fieldName === "name") {
        setConfirmUser({
          ...confirmUser,
          [fieldName]: event.target.value,
          nameLat: translit(event.target.value), // важно!
        });
      } else if (fieldName === "inn") {
        let value = event.target.value
          .toString()
          .replaceAll(" ", "");
        if (value.length <= 14) {
          setConfirmUser({
            ...confirmUser,
            [fieldName]:
              value.startsWith("1") ||
              value.startsWith("2")
                ? value
                : value.slice(
                    0,
                    value.length - 1
                  ), // важно!
          });
        }
      } else if (fieldName === "passportNumber") {
        let value = event.target.value
          .toString()
          .replaceAll(" ", "")
          .toUpperCase();
        setConfirmUser({
          ...confirmUser,
          [fieldName]:
            value.startsWith("AN") ||
            value.startsWith("ID") ||
            value.length < 2
              ? value
              : value.slice(0, value.length - 2), // важно!
        });
      } else if (
        fieldName === "nameLat" ||
        fieldName === "surnameLat"
      ) {
        let value = event.target.value.replaceAll(
          /[а-я]/gi,
          ""
        );
        setConfirmUser({
          ...confirmUser,
          [fieldName]: value,
        });
      } else if (
        fieldName ===
          "existenceOfBeneficialOwner" ||
        fieldName ===
          "availabilityOfCitizenshipOrGreenCardUSA" ||
        fieldName === "isPolitic" ||
        fieldName === "stateEmployee"
      ) {
        setConfirmUser({
          ...confirmUser,
          [fieldName]:
            event.target.value === "true",
        });
      } else if (fieldName === "email") {
        setConfirmUser({
          ...confirmUser,
          [fieldName]:
            event.target.value.replaceAll(
              " ",
              ""
            ),
        });
      } else {
        setConfirmUser({
          ...confirmUser,
          [fieldName]: event.target.value,
        });
      }
      changeBtnDisabled();
    };
  const confirmResidenceAddressChange =
    (fieldName) => (value) => {
      if (fieldName === "residenceCityId")
        setConfirmUser({
          ...confirmUser,
          residenceAddress: {
            ...confirmUser.residenceAddress,
            residenceCityId: value.cityId,
            residenceCity: value.cityName,
          },
        });
      else {
        setConfirmUser({
          ...confirmUser,
          residenceAddress: {
            ...confirmUser.residenceAddress,
            [fieldName]: value,
          },
        });
      }
      changeBtnDisabled();
    };
  const changeBtnDisabled = () => {
    const required =
      settings && settings.bakaiInterface
        ? [
            "dateBirth",
            "dateExpiry",
            "dateIssue",
            "passportNumber",
            "surname",
            "name",
            "authority",
            "codeWord",
          ]
        : [
            "dateBirth",
            "dateExpiry",
            "dateIssue",
            "passportNumber",
            "surname",
            "name",
            "nameLat",
            "surnameLat",
            "authority",
            "email",
            "placeOfWork",
            "post",
            "codeWord",
          ];
    const residenceAdressRequired =
      settings && settings.bakaiInterface
        ? []
        : [
            "residenceCity",
            "residenceStreet",
            "residenceHouse",
          ];
    let requiredState = required.some(
        (item) =>
          typeof confirmUser[item] === "string" &&
          !Boolean(
            confirmUser[item].replaceAll(" ", "")
              .length
          )
      ),
      residenceAdressRequiredState =
        residenceAdressRequired.some(
          (item) =>
            typeof confirmUser.residenceAddress[
              item
            ] === "string" &&
            !Boolean(
              confirmUser.residenceAddress[
                item
              ].replaceAll(" ", "").length
            )
        );
    setDisabled(
      requiredState === true &&
        residenceAdressRequiredState === true
    );
  };
  const confirmUserClick = () => {
    setConfirm(true);
  };
  const confirmClose = () => {
    setConfirm(false);
  };

  const confirmUserRegistration = () => {
    let emailRegEx =
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (
      confirmUser.email.length &&
      !emailRegEx.test(confirmUser.email)
    ) {
      setErrorMessage({
        error: true,
        message: t(
          "lang-8c036cd9-76a8-4688-9acc-2de32bd4228e"
        ),
      });
      setNotification(true);
    }
    if (regSessionId) {
      setLoading(true);
      //@ts-ignore
      RegistrationAPI.confirmUserRegistrationInfo(
        regSessionId,
        confirmUser
      )
        .then((data) => {
          if (data.status === "success") {
            setLoading(false);
            setNotification(false);
            if (guestPasswordData) {
              setGuestRegistrationEnd(true);
            } else {
              return history.push(
                "/registration"
              );
            }
          } else {
            catchError(data.message);
          }
        })
        .catch(({ response }) => {
          catchError(response.data.message);
          setLoading(false);
        });
    }
    confirmClose();
  };
  const classes = useStyles();
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] =
    useState("");
  function searchCity(event) {
    let text = event.target.value;
    setSearchText(text);
    if (text && text.length) {
      setCitiesListSelect(
        citiesData.filter((item) =>
          item.cityName.match(
            new RegExp(`^${text}`, "gi")
          )
        )
      );
    } else {
      setCitiesListSelect(citiesData);
    }
  }
  return (
    <>
      {!guestRegistrationEnd ? (
        <div className="content passwordData">
          <Button
            className="cancel"
            color="primary"
            onClick={cencelClickOpen}>
            {t("cancel")}
          </Button>
          <div className="title">
            {t(
              "dataShouldBeEqualWithPassportData"
            )}
          </div>
          <div className="passwordData__form">
            <FormControl fullWidth>
              <InputLabel>
                {t("personalNumber")}
              </InputLabel>
              <Input
                inputProps={{
                  maxLength: 14,
                }}
                value={confirmUser.inn}
                onChange={confirmUserChange(
                  "inn"
                )}
                endAdornment={
                  <InputAdornment position="end">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 20,
                      }}>
                      <span
                        style={{
                          color: "#c3c3c3",
                        }}>
                        {confirmUser.inn.length +
                          ` / 14`}
                      </span>
                      <div
                        onClick={() =>
                          setPersonalNumber(true)
                        }>
                        <QuestionIcon />
                      </div>
                    </div>
                  </InputAdornment>
                }
              />
            </FormControl>
            <TextField
              fullWidth
              inputProps={{
                maxLength: 9,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span
                      style={{
                        color: "#c3c3c3",
                      }}>
                      {confirmUser.passportNumber
                        .length + ` / 9`}
                    </span>
                  </InputAdornment>
                ),
              }}
              label={t("passportNumber")}
              value={confirmUser.passportNumber}
              onChange={confirmUserChange(
                "passportNumber"
              )}
              required
            />
            <TextField
              fullWidth
              label={t("surname")}
              value={confirmUser.surname}
              onChange={confirmUserChange(
                "surname"
              )}
              required
            />
            <TextField
              fullWidth
              label={t("name")}
              value={confirmUser.name}
              onChange={confirmUserChange("name")}
              required
            />
            <TextField
              fullWidth
              label={t("middleName")}
              value={confirmUser.patronymic}
              onChange={confirmUserChange(
                "patronymic"
              )}
            />
            {settings &&
              !settings.bakaiInterface && (
                <TextField
                  fullWidth
                  label={t("latName")}
                  value={confirmUser.nameLat}
                  onChange={confirmUserChange(
                    "nameLat"
                  )}
                  required
                />
              )}
            {settings &&
              !settings.bakaiInterface && (
                <TextField
                  fullWidth
                  label={t("latSurname")}
                  value={confirmUser.surnameLat}
                  onChange={confirmUserChange(
                    "surnameLat"
                  )}
                  required
                />
              )}
            {settings &&
              !settings.bakaiInterface && (
                <TextField
                  fullWidth
                  label={t("nation")}
                  value={confirmUser.nation}
                  onChange={confirmUserChange(
                    "nation"
                  )}
                />
              )}
            {/* <TextField
                            fullWidth
                            label="Гражданство"
                            value={confirmUser.nationality}
                            onChange={confirmUserChange('nationality')}/> */}
            <InputMask
              mask="99.99.9999"
              value={confirmUser.dateBirth}
              onChange={confirmUserChange(
                "dateBirth"
              )}>
              {(inputProps: any) => (
                <TextField
                  {...inputProps}
                  label={
                    t("dateBirth") + " дд.мм.гггг"
                  }
                  required
                  fullWidth
                />
              )}
            </InputMask>
            <InputMask
              mask="99.99.9999"
              value={confirmUser.dateIssue}
              onChange={confirmUserChange(
                "dateIssue"
              )}>
              {(inputProps: any) => (
                <TextField
                  {...inputProps}
                  label={t("datePassport")}
                  required
                  fullWidth
                />
              )}
            </InputMask>
            <TextField
              fullWidth
              label={t("passportOrg")}
              value={confirmUser.authority}
              onChange={confirmUserChange(
                "authority"
              )}
              required
            />
            <InputMask
              mask="99.99.9999"
              value={confirmUser.dateExpiry}
              onChange={confirmUserChange(
                "dateExpiry"
              )}>
              {(inputProps: any) => (
                <TextField
                  {...inputProps}
                  label={t("passportExpDate")}
                  required
                  fullWidth
                />
              )}
            </InputMask>
            {settings &&
              !settings.btbInterface && (
                <TextField
                  fullWidth
                  label={t("citizenshipAddress")}
                  value={
                    confirmUser.registrationAddress
                  }
                  onChange={confirmUserChange(
                    "registrationAddress"
                  )}
                />
              )}
            {settings &&
              !settings.btbInterface &&
              !settings.bakaiInterface && (
                <>
                  <TextField
                    fullWidth
                    value={
                      confirmUser.residenceAddress
                        .residenceCity
                    }
                    label={t("Город проживания")}
                    onClick={(event) => {
                      setAnchorEl(
                        event.currentTarget
                      );
                      setOpenMenu(true);
                    }}
                    className={
                      classes.customSelect
                    }
                    InputProps={{
                      endAdornment: (
                        <Icon
                          component={
                            ArrowDropDownIcon
                          }
                          style={{
                            color: "#0000008a",
                          }}
                        />
                      ),
                    }}
                    required
                    disabled></TextField>
                  <Popover
                    open={openMenu}
                    onClose={() =>
                      setOpenMenu(false)
                    }
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    classes={{
                      paper: classes.menuPaper,
                    }}>
                    <InfiniteScroll
                      width={
                        window.innerWidth - 81
                      }
                      searchText={searchText}
                      onSearchTextChange={
                        searchCity
                      }
                      maxLength={
                        citiesListSelect.length
                      }
                      RenderItem={({
                        registerChild,
                        index,
                        style,
                      }) => {
                        let item =
                          citiesListSelect[index];
                        return (
                          <Button
                            fullWidth
                            ref={registerChild}
                            key={item.id}
                            style={{
                              ...style,
                              borderRadius: 0,
                              borderBottom:
                                "1px solid #767676",
                            }}
                            onClick={() => {
                              confirmResidenceAddressChange(
                                "residenceCityId"
                              )(item);
                              setOpenMenu(false);
                            }}>
                            <div>
                              <Typography
                                variant="body1"
                                style={{
                                  fontWeight: 400,
                                }}>
                                {item.cityName}
                              </Typography>
                              <Typography
                                variant="caption"
                                style={{
                                  color:
                                    "#767676",
                                }}>
                                {item.fullName}
                              </Typography>
                            </div>
                          </Button>
                        );
                      }}
                      searchPlaceholder={t(
                        "Поиск города"
                      )}
                    />
                  </Popover>
                </>
              )}
            {settings &&
              !settings.btbInterface &&
              !settings.bakaiInterface && (
                <TextField
                  fullWidth
                  label={t("Улица проживания")}
                  value={
                    confirmUser.residenceAddress
                      .residenceStreet
                  }
                  onChange={(event) =>
                    confirmResidenceAddressChange(
                      "residenceStreet"
                    )(event.target.value)
                  }
                  required
                />
              )}
            {settings &&
              !settings.btbInterface &&
              !settings.bakaiInterface && (
                <TextField
                  fullWidth
                  label={t("Дом проживания")}
                  value={
                    confirmUser.residenceAddress
                      .residenceHouse
                  }
                  onChange={(event) =>
                    confirmResidenceAddressChange(
                      "residenceHouse"
                    )(event.target.value)
                  }
                  required
                />
              )}
            {settings &&
              !settings.btbInterface &&
              !settings.bakaiInterface && (
                <TextField
                  fullWidth
                  label={t("Квартира проживания")}
                  value={
                    confirmUser.residenceAddress
                      .residenceFlat
                  }
                  onChange={(event) =>
                    confirmResidenceAddressChange(
                      "residenceFlat"
                    )(event.target.value)
                  }
                />
              )}
            {settings &&
              !settings.btbInterface && (
                <TextField
                  fullWidth
                  label={t("email")}
                  value={confirmUser.email}
                  onChange={confirmUserChange(
                    "email"
                  )}
                  required={
                    !settings.bakaiInterface
                  }
                />
              )}
            {settings && !settings.btbInterface && (
              <TextField
                fullWidth
                label={t("maritalStatus")}
                value={confirmUser.familyStatus}
                onChange={confirmUserChange(
                  "familyStatus"
                )}
                select>
                <MenuItem value="Холост/незамужем">
                  {t(
                    "lang-586a2610-b0ec-44f4-884e-45f2e7fa9eae"
                  )}
                </MenuItem>
                <MenuItem value="Женат/замужем">
                  {t(
                    "lang-800dc893-1ab0-41ea-abcd-46e4050cd914"
                  )}
                </MenuItem>
                <MenuItem value="В разводе">
                  {t(
                    "lang-991cbff2-a586-41ab-ac43-f00cc75841a6"
                  )}
                </MenuItem>
                <MenuItem value="Повторный брак">
                  {t(
                    "lang-a04c59a6-ce43-45f6-aae3-54dcfd8b944e"
                  )}
                </MenuItem>
                <MenuItem value="Вдовец / вдова">
                  {t(
                    "lang-5a6f089f-b9d4-4232-b001-5b57ddd8e4c8"
                  )}
                </MenuItem>
              </TextField>
            )}
            {settings &&
              !settings.btbInterface &&
              !settings.bakaiInterface && (
                <TextField
                  fullWidth
                  label={t("placeOfWork")}
                  value={confirmUser.placeOfWork}
                  onChange={confirmUserChange(
                    "placeOfWork"
                  )}
                  required
                />
              )}
            {settings &&
              !settings.btbInterface &&
              !settings.bakaiInterface && (
                <TextField
                  fullWidth
                  label={t("position")}
                  value={confirmUser.post}
                  onChange={confirmUserChange(
                    "post"
                  )}
                  required
                />
              )}
            {settings &&
              !settings.btbInterface && (
                <TextField
                  fullWidth
                  label={t("codeWord")}
                  value={confirmUser.codeWord}
                  onChange={confirmUserChange(
                    "codeWord"
                  )}
                  required
                />
              )}
            {settings && !settings.btbInterface && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}>
                <FormControl
                  component="fieldset"
                  style={{
                    width: "fit-content",
                  }}>
                  <FormLabel component="legend">
                    {t("haveBeneficialOwner")}
                  </FormLabel>
                  <RadioGroup
                    value={
                      confirmUser.existenceOfBeneficialOwner
                    }
                    onChange={confirmUserChange(
                      "existenceOfBeneficialOwner"
                    )}>
                    <FormControlLabel
                      name="ExistenceOfBeneficialOwnerYes"
                      value={true}
                      control={<Radio />}
                      label={t("yes")}
                    />
                    <FormControlLabel
                      name="ExistenceOfBeneficialOwnerNo"
                      value={false}
                      control={<Radio />}
                      label={t("no")}
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl
                  component="fieldset"
                  style={{
                    width: "fit-content",
                  }}>
                  <FormLabel component="legend">
                    {t("USCitizenship")}
                  </FormLabel>
                  <RadioGroup
                    value={
                      confirmUser.availabilityOfCitizenshipOrGreenCardUSA
                    }
                    onChange={confirmUserChange(
                      "availabilityOfCitizenshipOrGreenCardUSA"
                    )}>
                    <FormControlLabel
                      name="yes"
                      value={true}
                      control={<Radio />}
                      label={t("yes")}
                    />
                    <FormControlLabel
                      name="no"
                      value={false}
                      control={<Radio />}
                      label={t("no")}
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl
                  component="fieldset"
                  style={{
                    width: "fit-content",
                  }}>
                  <FormLabel component="legend">
                    {t("areYouPDL")}
                  </FormLabel>
                  <RadioGroup
                    value={confirmUser.isPolitic}
                    onChange={confirmUserChange(
                      "isPolitic"
                    )}>
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label={t("yes")}
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label={t("no")}
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl
                  component="fieldset"
                  style={{
                    width: "fit-content",
                  }}>
                  <FormLabel component="legend">
                    {t("areYouGovernment")}
                  </FormLabel>
                  <RadioGroup
                    value={
                      confirmUser.stateEmployee
                    }
                    onChange={confirmUserChange(
                      "stateEmployee"
                    )}>
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label={t("yes")}
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label={t("no")}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            )}
          </div>
          <div className="btnRow">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disableElevation
              disabled={
                loading ? loading : btnDisabled
              }
              startIcon={
                loading && (
                  <CircularProgress size={26} />
                )
              }
              onClick={confirmUserClick}>
              {t("confirm")}
            </Button>
          </div>
          <CalncelDilalog
            openCancel={openCancel}
            cencelClickClose={cencelClickClose}
            logout={true}
            backHistory="/"
          />
          <SnackbarAlert
            notification={notification}
            setNotification={setNotification}
            message={errorMessage.message}
            severity="error"
            vertical="top"
            horizontal="center"
          />
          <Dialog open={confirm}>
            <DialogTitle id="alert-dialog-title">
              {t("alert")}
            </DialogTitle>
            <DialogContent>
              {t("confirmCorrectness")}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={confirmClose}
                color="primary">
                {t("no")}
              </Button>
              <Button
                onClick={confirmUserRegistration}
                color="primary"
                autoFocus>
                {t("yes")}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={personalNumber}>
            <DialogContent>
              <div className="content personalNumber">
                <div className="title">
                  {t("personalNumber")}
                </div>
                <div className="personalNumber__icon">
                  <div className="personalNumber__title">
                    {t("newTypePassport")}
                  </div>
                  <img src={NewPassword} alt="" />
                </div>
                <div className="personalNumber__icon">
                  <div className="personalNumber__title">
                    {t("oldTypePassport")}
                  </div>
                  <img src={OldPassword} alt="" />
                </div>
                <div className="btnRow">
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() =>
                      setPersonalNumber(false)
                    }>
                    {t("back")}
                  </Button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        <RegistrationEnd
          guestRegistrationEnd={
            guestRegistrationEnd
          }
          setGuestRegistrationEnd={
            setGuestRegistrationEnd
          }
        />
      )}
    </>
  );
};

const useStyles = makeStyles({
  menuPaper: {
    width: "100%",
    maxHeight: 400,
    overflow: "auto",
    borderRadius: 4,
    padding: "0px 32px 32px",
    boxShadow: "0px 2px 25px rgba(0, 0, 0, 0.15)",
  },
  customSelect: {
    opacity: 1,
    "& .MuiFormLabel-root": {
      color: "#0000008a !important",
    },
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#000",
      fontWeight: 400,
      WebkitTextFillColor: "#000",
    },
    "& .MuiInput-underline.Mui-disabled:before": {
      borderBottomStyle: "solid !important",
    },
    "& .MuiInputBase-input": {
      cursor: "pointer !important",
    },
    "&:hover .MuiInput-underline.Mui-disabled:before":
      {
        borderBottom: "2px solid #000",
      },
  },
});

const mapStateToProps = (state: RootState) => ({
  regSessionId: state.registration.regSessionId,
  confirmUser: state.registration.confirmUser,
  settings: state.registration.settings,
});

const mapDispatchToProps = (
  dispatch: Dispatch<Action> &
    ThunkDispatch<any, any, AnyAction>
) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordData);
