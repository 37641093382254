export const setContract = (contract) => ({
  type: "SET_CONTRACT",
  contract,
});

export const setContractDetail = (contractDetail) => ({
  type: "SET_CONTRACT_DETAIL",
  contractDetail,
});

export const setDepartmentId = (departmentId) => ({
  type: "SET_DEPARTMENT_ID",
  departmentId,
});

export const setHtml = (html) => ({
  type: "SET_HTML",
  html,
});

export const setPdf = (pdf) => ({
  type: "SET_PDF",
  pdf,
});

export const setContractConfirm = (contract) => ({
  type: "SET_CONTRACT_CONFIRM",
  contract,
});
