import React from "react";
import {
  List,
  CellMeasurer,
  CellMeasurerCache,
  InfiniteLoader,
} from "react-virtualized";

import SearchIcon from "../../icons/Search.svg";
import { StyledTextField } from "./SearchComponent";

// In this example, average cell height is assumed to be about 50px.
// This value will be used for the initial `Grid` layout.
// Width is not dynamic.
const cache = new CellMeasurerCache({
  defaultHeight: 50,
  fixedWidth: true,
});

export default function InfiniteScroll({
  width,
  maxLength,
  searchText,
  RenderItem,
  RenderHeader = false,
  searchPlaceholder,
  onSearchTextChange,
}) {
  const rowRenderer = ({ index, key, parent, style }) => (
    <CellMeasurer
      key={key}
      cache={cache}
      columnIndex={0}
      parent={parent}
      rowIndex={index}>
      {({ registerChild }) => (
        <RenderItem registerChild={registerChild} style={style} index={index} />
      )}
    </CellMeasurer>
  );
  return (
    <>
      <div
        style={{ width: "100%", paddingTop: 32 }}
        onClick={(e) => e.stopPropagation()}>
        <StyledTextField
          fullWidth
          autoFocus
          value={searchText}
          onChange={onSearchTextChange}
          onKeyDown={(e) => {
            if (e.key !== "Escape") {
              // Prevents autoselecting item while typing (default Select behaviour)
              e.stopPropagation();
            }
          }}
          placeholder={searchPlaceholder}
          InputProps={{
            startAdornment: (
              <img
                style={{ marginRight: 12 }}
                src={SearchIcon}
                alt="search icon"
              />
            ),
          }}
          style={{
            marginBottom: 20,
          }}
        />
      </div>
      {RenderHeader && <RenderHeader />}
      <InfiniteLoader
        isRowLoaded={({ index }) => index < maxLength}
        loadMoreRows={() => {}}
        rowCount={1000}
        loa>
        {({ onRowsRendered, registerChild }) => (
          <List
            width={width}
            height={250}
            ref={registerChild}
            rowCount={maxLength}
            deferredMeasurementCache={cache}
            rowHeight={cache.rowHeight}
            rowRenderer={rowRenderer}
            onRowsRendered={onRowsRendered}
          />
        )}
      </InfiniteLoader>
    </>
  );
}
