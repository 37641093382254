// @ts-nocheck
import React, { Dispatch, useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { Action, AnyAction } from "redux";
import { Switch, Route, useHistory } from "react-router-dom";
import Authorization from "../Authorization/Authorization";
import StepIndividual from "../Registration/StepIndividual";
import PhotoFace from "../Registration/PhotoFace";
import PasswordFace from "../Registration/PasswordFace";
import PasswordBack from "../Registration/PasswordBack";
import PasswordData from "../Registration/PasswordData";
import PersonalNumber from "../Registration/PersonalNumber";
import RegistrationEnd from "../Registration/RegistrationEnd";
import Identification from "../Registration/Identification";
import { SessionAPI, IUserInfo } from "../../api/SessionAPI";
import MobileMenu from "../MobileMenu/MobileMenu";
import ClientPage from "../Client/ClientPage";
import Axios from "axios";
import {
  InstanceDocumentHead,
  InstanceHead,
  InstanceHeadRegistration,
} from "../../api/InstanceHead";
import { ISettings, NewsLongPolling } from "../../store/Registration/types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import {
  ClientDocumentsAPI,
  IPaymentOrders,
} from "../../api/ClientDocumentsAPI";
import ContractInfo from "../bricks/ContractInfo";
import { GetNews } from "../../utils/getNews";
import { RegistrationAPI } from "../../api/RegistrationAPI";
import { localStorageGetItem } from "../../utils/storage";
import PaymentOrder from "../Client/PaymentOrder";
import { useTranslation } from "react-i18next";
import { DocumentsAPI } from "../../api/DocumentsAPI";

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

const AppStack: React.FC<Props> = (props) => {
  const {
    isAuthorized,
    isAuthorizedAction,
    settingsAction,
    sessionId,
    setLoaderAction,
    loading,
    logoutAction,
    regLogoutAction,
    newsLongPollingAction,
    newsLongPolling,
    setPdf,
  } = props;
  const { pathname } = window.location;
  const [pulingOpen, setPulingOpen] = useState(false);
  const [settingsLoaded, setSettingsLoaded] = useState(false);
  const [userInfo, setUserInfo] = useState<IUserInfo>({
    inn: "",
    fullName: "",
    userId: 0,
  });
  const [childDocuments, setChildDocuments] = useState<any>([]);
  const [currentContract, setCurrentContract] = useState<any | null>(null);
  const [currentPaymentOrder, setCurrentPaymentOrder] =
    useState<IPaymentOrders | null>(null);
  const [document, setDocument] = useState<any>({});
  const [doc, setDoc] = useState({});
  const { t } = useTranslation();
  const [isVideoCallActive, setIsVideoCallActive] = useState(false);
  const [apiUrl, setApiUrl] = useState("");
  //@ts-ignore
  const [request, setRequest] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const settingsUrl = `/companiesSettings/${window.location.hostname}/settings.json`;
    const catchError = (response: any) => {
      if (response.status === 401) {
        logoutAction();
      }
      if (response.status === 400) {
        console.error(response.message);
      }
      setLoaderAction(false);
    };
    const catchErrorReg = (response: any) => {
      if (response.status === 401) {
        regLogoutAction();
      }
      if (response.status === 400) {
        regLogoutAction();
      }
      setLoaderAction(false);
    };
    Axios.get(settingsUrl).then((res) => {
      localStorage.setItem("apiUrl", res.data.apiUrl);
      settingsAction(res.data);
      setApiUrl(res.data.apiUrl);
      InstanceDocumentHead.init(res.data.apiUrl);
      InstanceHead.init(res.data.apiKey);
      InstanceHeadRegistration.init(res.data.apiKey);
      if (sessionId) {
        setLoaderAction(true);
        GetNews.subscribe();
        SessionAPI.getUserInfo(sessionId).then((data) => {
          setUserInfo({
            inn: data.inn,
            fullName: data.fullName,
            userId: data.userId,
          });
        });
        SessionAPI.checkSession(sessionId)
          .then((res) => {
            if (res.status === 200) {
              isAuthorizedAction(true);
            } else {
              isAuthorizedAction(false);
            }
            setLoaderAction(false);
          })
          .catch(({ response }) => catchError(response));
      }
      const regSessionId = localStorageGetItem("regSessionId");
      if (regSessionId) {
        setLoaderAction(true);
        GetNews.subscribe();
        RegistrationAPI.getRegistrationStatus(regSessionId)
          .then((data) => {
            if (data.status === "success") {
              isAuthorizedAction(true);
            }
            setLoaderAction(false);
          })
          .catch(({ response }) => catchErrorReg(response));
      }
      setSettingsLoaded(true);
    });
  }, [
    settingsAction,
    sessionId,
    isAuthorizedAction,
    setLoaderAction,
    logoutAction,
    regLogoutAction,
    newsLongPollingAction,
  ]);

  useEffect(() => {
    //@ts-ignore
    if (newsLongPolling && newsLongPolling.length) {
      //@ts-ignore
      newsLongPolling.forEach((item: NewsLongPolling) => {
        DocumentsAPI.clientDeliveredDocuments(
          sessionId,
          item.eventParameters.documentId
        ).catch((err) => console.error(err));
        if (item.eventName === "NewDocument") {
          setDoc(item);
        }
      });
    }
  }, [newsLongPolling, sessionId]);

  useEffect(() => {
    //@ts-ignore
    if (
      newsLongPolling &&
      newsLongPolling.length &&
      typeof doc.eventParameters === "object"
    ) {
      setPulingOpen(true);
    }
    //@ts-ignore
    setDocument(doc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doc, newsLongPolling]);

  const pulingOpenClick = () => {
    setRequest(true);
    if ((sessionId || regSessionId) && doc.eventParameters) {
      //@ts-ignore
      if (doc.eventParameters.documentType === "Pdf") {
        //@ts-ignore
        let childDocuments;
        if (doc.eventParameters.childDocuments) {
          let childPdfDocuments = [];
          doc.eventParameters.childDocuments.forEach((childDoc) => {
            childPdfDocuments.push({
              url: `${apiUrl}/documents/GetDocumentPdf/${sessionId}/${childDoc.documentId}`,
              description: childDoc.documentDescription.split(".")[0],
            });
          });
          childDocuments = childPdfDocuments;
        }
        //@ts-ignore
        setPdf({
          mainDoc: {
            url: `${apiUrl}/documents/GetDocumentPdf/${sessionId}/${doc.eventParameters.documentId}`,
            description: doc.eventParameters.documentDescription.split(".")[0],
          },
          childDocuments,
        });
      }
      if (
        doc.eventParameters.documentType === "DocumentByTemplate" ||
        doc.eventParameters.documentType === "Pdf"
      ) {
        //@ts-ignore
        if (doc.eventParameters.documentType === "DocumentByTemplate") {
          setChildDocuments(doc.eventParameters.childDocuments);
        }
        //@ts-ignore
        ClientDocumentsAPI.getDocument(
          sessionId,
          doc.eventParameters.documentId
        )
          .then((res) => {
            setRequest(false);
            //@ts-ignore
            setPulingOpen(false);
            setCurrentContract(res);
            props.setContractConfirm(res);
            if (doc.eventParameters.documentType === "DocumentByTemplate") {
              props.setHtml(res.documentHtml);
            }
          })
          .catch(({ response }) => {
            setRequest(false);
          });
        //@ts-ignore
      } else if (doc.eventParameters.documentType === "PaymentOrder") {
        //@ts-ignore
        ClientDocumentsAPI.getDocument(
          sessionId,
          doc.eventParameters.documentId
        )
          .then((data) => {
            setRequest(false);
            setCurrentPaymentOrder(data);
            setPulingOpen(false);
          })
          .catch(({ response }) => {
            setRequest(false);
            console.error(response);
          });
      }

      newsLongPolling.forEach((item: NewsLongPolling) => {
        if (newsLongPolling?.length) {
          DocumentsAPI.clientReadDocuments(
            sessionId,
            item.eventParameters.documentId
          )
            .then((res) => {})
            .catch((err) => console.error(err))
            .finally(() => {
              setRequest(false);
            });
        }
      });
    }
  };
  // const userAgent = navigator.userAgent.toLowerCase();
  // if(!/chrome/.test(userAgent) || !/safari/.test(userAgent)) {
  //     return <Forbid />
  // }

  // if (loading || !settingsLoaded) {
  //     return <Spinner/>
  // }

  if (!isVideoCallActive && sessionId && window.location.pathname === "/") {
    history.push("/client");
  } else if (
    !isVideoCallActive &&
    !sessionId &&
    !props.regSessionId &&
    window.location.pathname === "/client"
  ) {
    history.push("/");
  }

  // if (!isAuthorized) {
  //     return <Authorization setIsVideoCallActive={setIsVideoCallActive} />
  // }
  console.log(pathname.match(/client/) || pathname.match(/identification/));
  return (
    <div className="container">
      {/* <PasswordData guestPasswordData={true} /> */}
      {request && (
        <div className="main-preloader">
          <div className="pulse"></div>
        </div>
      )}
      {(pathname.match(/client/) || pathname.match(/identification/)) && (
        <>
          {pathname.match(/identification/) ? (
            <Button
              onClick={() => history.push("/")}
              style={{
                position: "absolute",
                bottom: 6,
                textAlign: "center",
                border: "1px solid red",
                width: 100,
                left: "calc(50% - 50px)",
                padding: "4px 10px",
                display: "block",
                height: 42,
                color: "#fff",
                background: "red",
                zIndex: 10,
              }}>
              {t("logout")}
            </Button>
          ) : (
            <MobileMenu />
          )}
        </>
      )}
      <Dialog open={pulingOpen} className="modal confirm">
        <DialogTitle>{t("newDocument")}</DialogTitle>
        <DialogContent>
          <p className="modal__text">
            {t("dearCustomerPleaseReadTheDocument")}
          </p>
        </DialogContent>
        <DialogActions className="modal__actions">
          <Button onClick={() => setPulingOpen(false)} color="primary">
            {t("cancel")}
          </Button>
          <Button
            onClick={pulingOpenClick}
            variant="contained"
            color="primary"
            disableElevation>
            {t("open")}
          </Button>
        </DialogActions>
      </Dialog>
      {props.contractConfirm && (
        <ContractInfo
          childDocuments={childDocuments}
          id={document && document.eventParameters.documentId}
          departmentId={currentContract.departmentId}
          html={props.html}
          contract={props.contractConfirm}
          userInfo={userInfo}
          document={document}
          setCurrentContract={setCurrentContract}
        />
      )}
      {currentPaymentOrder && (
        <PaymentOrder
          paymentOrder={currentPaymentOrder}
          setPaymentDetail={setCurrentPaymentOrder}
          userInfo={userInfo}
        />
      )}
      <Switch>
        <Route
          path="/"
          render={(props) => (
            <Authorization
              {...props}
              setIsVideoCallActive={setIsVideoCallActive}
            />
          )}
          exact
        />
        <Route path="/stepIndividual" component={StepIndividual} />
        <Route path="/photo-face" component={PhotoFace} />
        <Route path="/password-face" component={PasswordFace} />
        <Route path="/password-back" component={PasswordBack} />
        <Route path="/password-data" component={PasswordData} />

        <Route path="/personal-number" component={PersonalNumber} />
        <Route path="/registration" component={RegistrationEnd} />
        <Route path="/identification" component={Identification} />
        <Route exact path="/client" component={ClientPage} />
      </Switch>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isAuthorized: state.registration.isAuthorized,
  sessionId: state.registration.sessionId,
  regSessionId: state.registration.regSessionId,
  loading: state.registration.loading,
  newsLongPolling: state.registration.newsLongPolling,
  html: state.documents.html,
  contractConfirm: state.documents.contractConfirm,
});

const mapDispatchToProps = (
  dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
  isAuthorizedAction: (isAuthorized: boolean) =>
    dispatch(actions.registration.isAuthorizedAction(isAuthorized)),
  settingsAction: (settings: ISettings) =>
    dispatch(actions.registration.settingsAction(settings)),
  setLoaderAction: (loading: boolean) =>
    dispatch(actions.registration.setLoaderAction(loading)),
  logoutAction: () => dispatch(actions.registration.logoutAction()),
  regLogoutAction: () => dispatch(actions.registration.regLogoutAction()),
  newsLongPollingAction: (newsLongPolling: NewsLongPolling) =>
    dispatch(actions.registration.newsLongPollingAction(newsLongPolling)),
  setHtml: (html) => dispatch(actions.documents.setHtml(html)),
  setPdf: (pdf) => dispatch(actions.documents.setPdf(pdf)),
  setContractConfirm: (contract) =>
    dispatch(actions.documents.setContractConfirm(contract)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppStack);
