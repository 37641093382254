import { combineReducers } from 'redux';
import registration from './Registration/reducer';
import documents from './Documents/reducer';

const rootReducer = combineReducers({
    registration,
    documents
});


export default rootReducer;