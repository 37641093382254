import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

type Props = {
  lanOpen: boolean;
  langClickClose: () => void;
};
const LanguageDialog: React.FC<Props> = ({ lanOpen, langClickClose }) => {
  const { t, i18n } = useTranslation();
  const [value, setValue] = React.useState(i18n.language);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    i18n.changeLanguage(event.target.value);
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <Dialog open={lanOpen} onClose={langClickClose} className="modal confirm">
      <DialogTitle>{t("choiseLang")}</DialogTitle>
      <DialogContent>
        <RadioGroup
          className="modal__RadioGroup"
          value={value}
          onChange={handleChange}>
          <FormControlLabel
            value="kg"
            control={<Radio color="primary" />}
            label="Кыргызча"
          />
          <FormControlLabel
            value="ru"
            control={<Radio color="primary" />}
            label="Русский"
          />
        </RadioGroup>
      </DialogContent>
      {/* <DialogActions className='modal__actions'>
        <Button onClick={langClickClose} color='primary'>
          {t("cancel")}
        </Button>
        <Button
          onClick={langClickClose}
          variant='contained'
          color='primary'
          disableElevation
        >
          {t("confirm")}
        </Button>
      </DialogActions> */}
    </Dialog>
  );
};

export default LanguageDialog;
