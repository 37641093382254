import React, { Dispatch } from "react";
import { connect } from "react-redux";
import { actions } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { Action, AnyAction } from "redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { localStorageRemoveItem } from "../../utils/storage";

type Props = ReturnType<typeof mapDispatchToProps> & {
  openCancel: boolean;
  cencelClickClose: () => void;
  onConfirm?: () => void;
  backHistory: string;
  logout?: boolean;
};

const CalncelDilalog: React.FC<Props> = ({
  openCancel,
  cencelClickClose,
  backHistory,
  logout,
  onConfirm,
}) => {
  const { t } = useTranslation();
  let history = useHistory();

  const cancelClick = () => {
    cencelClickClose();
    if (logout && logout === true) {
      localStorageRemoveItem("regSessionId");
      history.push("/");
      window.location.reload();
      return;
    }
    if (onConfirm) {
      onConfirm();
    }
    return history.push(backHistory);
  };

  return (
    <Dialog open={openCancel} className="modal cancel">
      <DialogTitle>{t("cancel")}</DialogTitle>
      <DialogContent>
        <p className="modal__text">{t("confirmCancel")}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={cencelClickClose} color="primary" size="small">
          {t("no")}
        </Button>
        <Button onClick={cancelClick} color="secondary" size="small">
          {t("yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
  isAuthorizedAction: (isAuthorized: boolean) =>
    dispatch(actions.registration.isAuthorizedAction(isAuthorized)),
});

export default connect(null, mapDispatchToProps)(CalncelDilalog);
