import React, { Dispatch, useState, useEffect } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { Action, AnyAction } from "redux";
import { Tabs, Tab } from "@material-ui/core";
import {
  NextIcon,
  DesctopGreenIcon,
  DocumentIcon,
  EmptyIcon,
} from "../../icons/icons";
import {
  ClientDocumentsAPI,
  IPaymentOrders,
} from "../../api/ClientDocumentsAPI";
import { IUserInfo } from "../../api/SessionAPI";
import PaymentOrder from "./PaymentOrder";
import "moment/locale/ru";
import moment from "moment";
import { uuidv4 } from "../../utils/generateId";
import { useTranslation } from "react-i18next";

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> & {
    userInfo: IUserInfo;
  };

const Documents: React.FC<Props> = (props) => {
  const {
    sessionId,
    userInfo,
    documentStatusSuccess,
    paymentTabValueAction,
    paymentTabValue,
    regSessionId,
  } = props;
  const { t } = useTranslation();
  const [paymentOrders, setPaymentOrders] = useState<IPaymentOrders[]>([]);
  const [paymentOrder, setPaymentOrder] = useState<IPaymentOrders | null>(null);
  const [paymentDetail, setPaymentDetail] = useState(false);
  const [historyPaymentOrders, setHistoryPaymentOrders] = useState<
    IPaymentOrders[]
  >([]);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    paymentTabValueAction(newValue);
  };
  let session = sessionId || regSessionId;
  useEffect(() => {
    if (session) {
      ClientDocumentsAPI.getDocuments(session, "New", "PaymentOrder")
        .then((data) => {
          setPaymentOrders(data);
        })
        .catch(({ response }) => console.error(response));
      ClientDocumentsAPI.getDocuments(session, "Signed", "PaymentOrder")
        .then((data) => {
          setHistoryPaymentOrders(data);
        })
        .catch(({ response }) => console.error(response));
    }
  }, [session, documentStatusSuccess]);

  const paymentOrderClick = (paymentOrder: IPaymentOrders) => {
    setPaymentOrder(paymentOrder);
    setPaymentDetail(true);
  };
  const paymentGroup = (array: IPaymentOrders[]) => {
    const data = array
      .sort((a, b) => {
        let textA = a.createdDate;
        let textB = b.createdDate;
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      })
      .reduce((payment: any, elem: any) => {
        const date = moment(elem.createdDate).format("DD MMMM YYYY");
        if (!payment[date]) {
          payment[date] = [];
        }
        payment[date].push(elem);
        return payment;
      }, {});
    return data;
  };

  const paymentsGroup = Object.keys(paymentGroup(paymentOrders)).map(
    (date: string) => {
      return {
        date,
        idx: uuidv4(),
        payments: paymentGroup(paymentOrders)[date],
      };
    }
  );
  const historyPaymentGroup = Object.keys(
    paymentGroup(historyPaymentOrders)
  ).map((date: string) => {
    return {
      date,
      idx: uuidv4(),
      payments: paymentGroup(historyPaymentOrders)[date],
    };
  });
  return (
    <>
      {!paymentDetail && (
        <div className="document">
          <div className="title">{t("moneyOrders")}</div>
          <Tabs
            value={paymentTabValue}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example">
            <Tab label={t("tabNew")} {...a11yProps(0)} />
            <Tab label={t("tabHistory")} {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={paymentTabValue} index={0}>
            {!paymentOrders.length && (
              <div className="empty">
                <EmptyIcon />
                <div className="empty__title">{t("createDocumentTitle")}</div>
              </div>
            )}
            <div>
              {paymentsGroup.map((paymentOrder) => {
                return (
                  <>
                    <div className="tabPanel__date">{paymentOrder.date}</div>
                    {paymentOrder.payments.map((paymentOrderChild: any) => {
                      let data = JSON.parse(paymentOrderChild.documentJson);
                      return (
                        <div
                          key={"new-" + paymentOrderChild.documentId}
                          className="documentBlock"
                          onClick={() => paymentOrderClick(paymentOrderChild)}>
                          <div className="documentBlock__icon">
                            <DocumentIcon />
                          </div>
                          <div>
                            <div className="documentBlock__title">
                              {data.paymentRecipient.name}
                            </div>
                            <div className="documentBlock__price">
                              Сумма: {data.paymentRecipient.amount}{" "}
                              {data.paymentRecipient.currency}
                            </div>
                          </div>
                          <div className="documentBlock__endIcon">
                            <NextIcon />
                          </div>
                        </div>
                      );
                    })}
                  </>
                );
              })}
            </div>
            {/*: <div className="tabPanel__date"><Moment*/}
            {/*    format="D MMMM YYYY">{paymentOrders[paymentOrders.length - 1].createdDate}</Moment></div>}*/}
          </TabPanel>
          <TabPanel value={paymentTabValue} index={1}>
            {!historyPaymentOrders.length && (
              <div className="empty">
                <EmptyIcon />
                <div className="empty__title">{t("createDocumentTitle")}</div>
              </div>
            )}

            <div>
              {historyPaymentGroup.map((paymentOrder) => {
                return (
                  <>
                    <div className="tabPanel__date">{paymentOrder.date}</div>
                    {paymentOrder.payments.map((paymentOrderChild: any) => {
                      let data = JSON.parse(paymentOrderChild.documentJson);
                      return (
                        <div
                          key={"new-" + paymentOrderChild.documentId}
                          className="documentBlock"
                          onClick={() => paymentOrderClick(paymentOrderChild)}>
                          <div className="documentBlock__icon">
                            <DesctopGreenIcon />
                          </div>
                          <div>
                            <div className="documentBlock__title">
                              {data.paymentRecipient.name}
                            </div>
                            <div className="documentBlock__price">
                              Сумма: {data.paymentRecipient.amount}{" "}
                              {data.paymentRecipient.currency}
                            </div>
                          </div>
                          <div className="documentBlock__endIcon">
                            <NextIcon />
                          </div>
                        </div>
                      );
                    })}
                  </>
                );
              })}
            </div>
          </TabPanel>
        </div>
      )}
      {paymentOrder && paymentDetail && (
        <PaymentOrder paymentOrder={paymentOrder} userInfo={userInfo} />
      )}
    </>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && <div className="tabPanel">{children}</div>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const mapStateToProps = (state: RootState) => ({
  sessionId: state.registration.sessionId,
  documentStatusSuccess: state.registration.documentStatusSuccess,
  paymentTabValue: state.registration.paymentTabValue,
  regSessionId: state.registration.regSessionId,
});

const mapDispatchToProps = (
  dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
  logoutAction: () => dispatch(actions.registration.logoutAction()),
  paymentTabValueAction: (paymentTabValue: number) =>
    dispatch(actions.registration.paymentTabValueAction(paymentTabValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
